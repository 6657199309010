import {DateTime} from 'luxon';

export const dateConvert = (value) => {
    return DateTime.fromISO(value).toFormat('dd LLL yyyy HH:mm');
};
export const dateConvertPattern = (value, pattern = 'dd LLL yyyy') => {
    if (value !== '') {
        return DateTime.fromISO(value).toFormat(pattern);
    }
    return '';
};

export const currencyFormatter = (value) => {
    return `Rp. ${Number(value).toLocaleString() || 0}`;
};

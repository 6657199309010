import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {Button} from '@components';

const TemplateFlowTable = ({
    recordsData,
    isLoadingData,
    editDetail,
    deleteDetail
}) => {
    return (
        <div>
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        {/* <th> */}
                        {/*    <br /> */}
                        {/* </th> */}
                        <th className="text-left">Flow Name</th>
                        <th className="text-left">Description</th>
                        <th className="text-center">Number of Approver</th>
                        <th className="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={6}>
                                <b>Loading Data...</b>
                            </td>
                        </tr>
                    )}
                    {recordsData.length > 0 &&
                        !isLoadingData &&
                        recordsData.map((row, index) => {
                            return (
                                <tr key={`tr-rows-${uuidv4()}`}>
                                    {/* <td> */}
                                    {/*    <div className="icheck-primary small"> */}
                                    {/*        <input */}
                                    {/*            key={`checkbox-${uuidv4()}`} */}
                                    {/*            type="checkbox" */}
                                    {/*            value={row.flowId} */}
                                    {/*            id={`checkbox-${index}`} */}
                                    {/*        /> */}
                                    {/*        <label */}
                                    {/*            htmlFor={`checkbox-${index}`} */}
                                    {/*        > */}
                                    {/*            <span /> */}
                                    {/*        </label> */}
                                    {/*    </div> */}
                                    {/* </td> */}
                                    <td>{row.flowName}</td>
                                    <td>{row.flowDesc}</td>
                                    <td className="text-center">
                                        {row.approverCount}
                                    </td>
                                    <td>
                                        <div className="float-right">
                                            <Button
                                                icon="edit"
                                                theme="outline-primary"
                                                className="mr-1"
                                                onClick={() => {
                                                    editDetail(row.flowId);
                                                }}
                                            >
                                                Edit Flow
                                            </Button>
                                            <Button
                                                icon="delete"
                                                theme="outline-danger"
                                                onClick={() => {
                                                    deleteDetail(row.flowId);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    {recordsData.length <= 0 && !isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={6}>
                                <b>No Data</b>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

TemplateFlowTable.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    editDetail: PropTypes.func.isRequired
};

export default TemplateFlowTable;

import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonIcon,
    ContentHeader,
    PaginationComponent
} from '@components';
import DocuGate from '@app/services/network/docugate';
import ArchivesTable from '@pages/document/ArchivesTable';
import {useHistory, withRouter} from 'react-router-dom';
import RouteUrl from '@app/routes/RouteUrl';

const Archives = () => {
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);

    const fetchDocumentAPI = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getDocumentsArchieve(
                Number(pageNumber),
                recordsPerPage,
                'createdAt:desc'
            );

            if (response) {
                if (response.results) {
                    setRecordsData(response.results);
                    setRecordsTotal(response.totalResults);
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        fetchDocumentAPI(1);
    };

    const history = useHistory();

    const goPageAnnotate = (documentId) => {
        history.push(
            RouteUrl.DocumentSigning.replace(':templateId', documentId)
        );
    };
    const goPageDownload = (documentId) => {
        history.push(
            RouteUrl.DocumentGenerate.replace(':templateId', documentId)
        );
    };

    const UploadButton = withRouter(({history}) => (
        <Button
            icon="upload"
            className="ml-1 mr-1"
            onClick={() => {
                history.push(`${RouteUrl.DocumentsUpload}`);
            }}
        >
            Upload Document
        </Button>
    ));

    /*
     * Initial
     */
    useEffect(() => {
        fetchDocumentAPI(1);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {}, [recordsData]);
    useEffect(() => {}, [recordsTotal]);
    useEffect(() => {
        fetchDocumentAPI(currentPage);
    }, [currentPage]);

    return (
        <div>
            <ContentHeader title="Archives" />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="float-left">
                                <div className="input-group input-group-sm">
                                    <ButtonIcon
                                        icon="refresh"
                                        theme="outline-primary"
                                        onClick={handleRefresh}
                                    />
                                </div>
                            </div>
                            {/* <div className="float-right"> */}
                            {/*    <div className="input-group input-group-sm"> */}
                            {/*        <div> */}
                            {/*            <UploadButton /> */}
                            {/*        </div> */}
                            {/*    </div> */}
                            {/* </div> */}
                        </div>
                        <div className="card-body p-0">
                            <ArchivesTable
                                recordsData={recordsData}
                                isLoadingData={isLoading}
                                goPageAnnotate={goPageAnnotate}
                                goPageDownload={goPageDownload}
                            />
                        </div>
                        <div className="card-footer">
                            <PaginationComponent
                                recordsTotal={recordsTotal}
                                recordsPerPage={recordsPerPage}
                                isLoadingData={isLoading}
                                onPageChanges={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Archives;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown} from '@components';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logoutUser} from '@store/reducers/auth';

const ProfilesDropdown = () => {
    const history = useHistory();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const logOut = (event) => {
        event.preventDefault();
        setDropdownOpen(false);
        dispatch(logoutUser());
        history.push('/login');
    };

    const navigateToProfile = (event) => {
        event.preventDefault();
        setDropdownOpen(false);
        history.push('/profile');
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            onChange={(open) => setDropdownOpen(open)}
            size="md"
            className="user-menu"
            buttonTemplate={
                <img
                    src="/img/default-profile.png"
                    className="user-image img-circle elevation-2"
                    alt="User"
                />
            }
            menuTemplate={
                <>
                    <Link
                        to="/profile"
                        onClick={navigateToProfile}
                        className="dropdown-item"
                    >
                        <i className="fas fa-user mr-2" />
                        <span>{t('header.user.profile')}</span>
                    </Link>
                    <div className="dropdown-divider" />
                    <Link
                        to="/login"
                        onClick={logOut}
                        className="dropdown-item"
                    >
                        <i className="fas fa-sign-out-alt mr-2" />
                        <span>{t('login.button.signOut')}</span>
                    </Link>
                </>
            }
        />
    );
};

export default ProfilesDropdown;

import React from 'react';
import {ContentHeader} from '@components';

const Subscription = () => {
    const btnTxt = 'Subscribe';

    return (
        <>
            <ContentHeader title="Subscription" />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <tr>
                            <td>Your Plan</td>
                        </tr>
                    </div>
                </div>
                <div
                    className="container-fluid"
                    style={{border: '2px solid blue'}}
                >
                    <div className="row">
                        <div className="col" style={{border: '2px solid red'}}>
                            <tr>
                                <td>Trial</td>
                            </tr>
                        </div>
                        <div
                            className="col"
                            style={{
                                display: 'flex',
                                border: '2px solid green',
                                marginLeft: 'auto',
                                backgroud: 'solid green'
                            }}
                        >
                            <tr>
                                <td>Trial</td>
                            </tr>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col" textallign="right">
                            <tr>We hope you enjoying our product.</tr>
                            <tr>
                                Improve your workflow by selecting a plan that
                                best suit your need.
                            </tr>
                        </div>
                        <div className="col" style={{display: 'flex'}}>
                            <button
                                style={{marginLeft: 'auto'}}
                                type="button"
                                className="pt-10 btn btn-primary"
                            >
                                {btnTxt}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Subscription;

/*
 * Get pager related data
 */
function paginator(
    totalItems = 0,
    currentPage = 1,
    pageSize = 10,
    limiter = 5
) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage = 0;
    let endPage = 0;

    if (totalPages <= limiter) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than limiter total pages so calculate start and end pages
        // eslint-disable-next-line no-lonely-if
        if (currentPage <= limiter) {
            startPage = 1;
            endPage = limiter;
        } else if (currentPage + limiter >= totalPages) {
            startPage = totalPages - limiter;
            endPage = totalPages;
        } else {
            startPage = currentPage - limiter;
            endPage = currentPage + (limiter - 1);
        }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
        totalItems,
        currentPage,
        pageSize,
        totalPages,
        startPage,
        endPage,
        startIndex,
        endIndex,
        pages
    };
}

/*
 * Export as a type
 */
export default paginator;

/*
  https://gitlab.com/witdiarta/annotation
 */
/* eslint-disable jsx-a11y/alt-text */
import _ from 'lodash';
import CanvasDraw from 'react-canvas-draw';
import {useRouter} from '@app/hooks/use-router';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    Container,
    Dimmer,
    Icon,
    Loader,
    Menu,
    Popup as _Popup,
    TextArea
} from 'semantic-ui-react';
import DocuGate from '@app/services/network/docugate';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import RouteUrl from '@app/routes/RouteUrl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ModalConfirm, PageLoading} from '@components';
import ModalInfo from '@app/components/modal/ModalInfo';
import {getFontByRatio} from '@app/helper/calculation';
import {rejectBase64, reviseBase64} from '../../services/images';

const {
    PdfLoader,
    PdfHighlighter,
    Popup,
    AreaHighlight
} = require('react-pdf-highlighter');

function SideMenu({
    activeButtons,
    onNext,
    onReject,
    onRevise,
    onApprove,
    onDone,
    saving,
    showUpload
}) {
    const buttons = [
        {
            key: 'reject',
            content: 'Reject',
            color: 'red',
            onClick: onReject
        },
        {
            key: 'revise',
            content: 'Revise',
            color: 'yellow',
            onClick: onRevise
        },
        {
            key: 'next',
            content: 'Next',
            color: 'blue',
            onClick: onNext
        },
        {
            key: 'start',
            content: 'Start Sign',
            color: 'green',
            onClick: onNext
        },
        {
            key: 'approve',
            content: 'Approve',
            color: 'green',
            onClick: onApprove
        },
        {
            key: 'done',
            content: 'Done',
            color: 'teal',
            onClick: onDone,
            loading: saving
        },
        {
            key: 'upload',
            content: 'Upload',
            color: 'green',
            onClick: showUpload
        }
    ];

    return (
        <div>
            <Button.Group
                // className={styles.menu}
                fluid
                buttons={_.filter(buttons, (button) =>
                    _.get(activeButtons, button.key)
                )}
            />
        </div>
    );
}

function TopMenu({state, setState, templateId, showConfirm}) {
    const history = useHistory();
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const doBack = async () => {
        history.push(RouteUrl.Documents);
    };

    const doSave = async (e) => {
        setIsLoadingSave(true);
        const highlights = _.get(state, 'highlights', []);

        setState((prev) => ({
            ...prev,
            saving: true
        }));

        const coordinates = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (const highlight of highlights) {
            if (highlight.save) {
                let payload = {};
                if (highlight.method === 'POST') {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        userId: highlight.userId,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                } else {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                }

                const data = await DocuGate.crudDocumentSigningCoordinate(
                    highlight.method,
                    templateId,
                    payload
                );

                if (!highlight.deleted) {
                    coordinates.push(data);
                }
            } else if (!highlight.save && !highlight.deleted) {
                coordinates.push(highlight);
            }
        }

        setState((prev) => ({
            ...prev,
            saving: false,
            activeUser: null,
            activeTool: null,
            highlights: _.map(coordinates, (coordinate) => ({
                position: coordinate.position || coordinate,
                // eslint-disable-next-line no-underscore-dangle
                _id: coordinate._id || coordinate.id,
                method: 'POST',
                save: false,
                id: String(Math.random()).slice(2),
                color:
                    coordinate.color ||
                    _.find(prev.users, {id: coordinate.userId}).color,
                type: coordinate.type
            }))
        }));
        setIsLoadingSave(false);
        showConfirm();
    };

    return (
        <Menu attached="top" borderless>
            <Menu.Item header>
                <h3>{state.documentTitle}</h3>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item>
                    <Button basic color="blue" onClick={() => showConfirm()}>
                        <Icon name="save" />
                        Close Sign
                    </Button>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
}

function PDF(props) {
    const {
        pdfUrl,
        users,
        highlights,
        pdfRef,
        drawRef,
        textRef,
        clearSign,
        onScrollChange
    } = props;

    const [textVal, setTextVal] = useState({value: ''});

    const [pdf, setPdf] = useState(
        <Dimmer active inverted>
            <Loader>Preparing Files</Loader>
        </Dimmer>
    );

    const keydUsers = _.keyBy(users, 'id');

    const RemoveButton = useCallback(({highlight, cb}) => {
        return (
            <Button
                circular
                icon="trash alternate outline"
                color="red"
                onClick={() => clearSign(highlight, cb)}
            />
        );
    }, []);

    const approvalButton = useCallback(({highlight, cb}) => {
        return (
            <Button
                circular
                icon="trash alternate outline"
                color="red"
                onClick={() => clearSign(highlight, cb)}
            />
        );
    }, []);

    useEffect(() => {
        let showRemove = false;

        const render = (pdfDocument) => {
            return (
                <PdfHighlighter
                    ref={pdfRef}
                    pdfDocument={pdfDocument}
                    highlights={highlights}
                    enableAreaSelection={false}
                    scrollRef={() => console.log('scrollRef')}
                    onScrollChange={onScrollChange}
                    onSelectionFinished={() =>
                        console.log('onSelectionFinished')
                    }
                    highlightTransform={(
                        highlight,
                        index,
                        setTip,
                        hideTip,
                        viewportToScaled,
                        screenshot,
                        isScrolledTo
                    ) => {
                        const {
                            response,
                            position: {boundingRect}
                        } = highlight;

                        const message = `by ${
                            keydUsers[highlight.userId].name
                        }`;

                        return (
                            <Popup
                                key={index}
                                onMouseOut={() => {
                                    hideTip();
                                    showRemove = false;
                                }}
                                onMouseOver={(popupContent) => {
                                    if (
                                        !showRemove &&
                                        response.image &&
                                        highlight.editable
                                    ) {
                                        setTip(
                                            highlight,
                                            (highlight) => popupContent
                                        );
                                        showRemove = true;
                                    }
                                }}
                                popupContent={
                                    <RemoveButton
                                        highlight={highlight}
                                        cb={hideTip}
                                    />
                                }
                            >
                                <AreaHighlight
                                    isScrolledTo={isScrolledTo}
                                    highlight={highlight}
                                    onChange={() => console.log('onChange')}
                                    disableDragging
                                    enableResizing={false}
                                    className={
                                        !highlight.editable
                                            ? 'AreaHighlight__part bg-transparent'
                                            : 'AreaHighlight__part'
                                    }
                                >
                                    {highlight.active &&
                                        highlight.type === 'draw' && (
                                            <CanvasDraw
                                                ref={drawRef}
                                                canvasWidth={boundingRect.width}
                                                canvasHeight={
                                                    boundingRect.height
                                                }
                                                backgroundColor="#FFE300"
                                                gridColor="#F7F7F7"
                                                brushRadius={1}
                                                lazyRadius={1}
                                            />
                                        )}

                                    {highlight.active &&
                                        highlight.type === 'text' && (
                                            <TextArea
                                                ref={textRef}
                                                placeholder="Input Text"
                                                style={{
                                                    minHeight:
                                                        boundingRect.height,
                                                    maxHeight:
                                                        boundingRect.height,
                                                    minWidth:
                                                        boundingRect.width,
                                                    maxWidth:
                                                        boundingRect.width,
                                                    fontSize: `${getFontByRatio(
                                                        boundingRect.width,
                                                        100
                                                    )}px`
                                                }}
                                                onChange={(event) => {
                                                    textRef.current.objectValue =
                                                        {
                                                            textValue:
                                                                event.target
                                                                    .value,
                                                            canvasWidth:
                                                                boundingRect.width,
                                                            canvasHeight:
                                                                boundingRect.height
                                                        };
                                                }}
                                            />
                                        )}

                                    {response.image && highlight.editable && (
                                        <img
                                            src={response.image}
                                            className="unselectable"
                                        />
                                    )}

                                    {response.image && !highlight.editable && (
                                        // eslint-disable-next-line react/jsx-pascal-case
                                        <_Popup
                                            content={message}
                                            trigger={
                                                // eslint-disable-next-line jsx-a11y/alt-text
                                                <img
                                                    src={response.image}
                                                    className="unselectable"
                                                />
                                            }
                                            inverted
                                            size="small"
                                            position="bottom center"
                                        />
                                    )}
                                </AreaHighlight>
                            </Popup>
                        );
                    }}
                />
            );
        };

        setPdf(
            <div
                style={{
                    height: '92vh',
                    position: 'relative'
                }}
            >
                <PdfLoader url={pdfUrl} beforeLoad={<PageLoading />}>
                    {render}
                </PdfLoader>
            </div>
        );
    }, [highlights]);

    return pdf;
}

export default function DocumentUserSign() {
    const router = useRouter();
    const pdfRef = useRef();
    const drawRef = useRef();
    const textRef = useRef();
    const {token} = router.query;

    const [highlightType, setHighlightType] = useState('');
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('');
    const [templateId, setTemplateId] = useState('');
    const [showConfirmFinish, setShowConfirmFinish] = useState(false);
    const [showConfirmCancel, setShowConfirmCancel] = useState(false);
    const [showCompleteInfo, setShowCompleteInfo] = useState(false);
    const [state, setState] = useState({
        documentTitle: '',
        pdf: null,
        saving: false,
        showUpload: false,
        activeButtons: null,
        highlights: []
    });

    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();
    const returnPageDocument = () => {
        history.push(RouteUrl.Documents);
    };

    // eslint-disable-next-line consistent-return
    const fetchDocumentSigning = async (documentId) => {
        try {
            setIsLoading(true);
            const responseModel = {
                documentTitle: '',
                pdf: '',
                id: '',
                coordinates: [],
                users: []
            };

            const response = await DocuGate.getUserSigningDocumentSigning(
                documentId,
                token
            );

            if (response) {
                const {document, users, coordinates} = response;

                responseModel.id = document.Id;
                responseModel.documentTitle = document.DocName;
                const documentUrl = `${
                    process.env.REACT_APP_GATEKEEPER_URL
                }${document.DocPath.replace('asset', 'user-sign')}`;
                responseModel.pdf = documentUrl;
                responseModel.users = users;
                responseModel.coordinates = coordinates;
            } else {
                toast.error('Document Not Found');
                returnPageDocument();
            }
            setIsLoading(false);
            console.log('responseModel ::', responseModel);
            return responseModel;
        } catch (error) {
            console.log('fetchDocumentSigning Error :: ', error);
            returnPageDocument();
            setIsLoading(false);
            toast.error('Document Not Found');
        }
    };

    const fetchTokenValidation = async (token) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getUserSigningValidation(token);

            if (response) {
                const {documentID, signerID} = response;
                setUserId(signerID);
                setTemplateId(documentID);
            } else {
                toast.error('Expired Link');
            }
            setIsLoading(false);
        } catch (error) {
            console.log('fetchTokenValidation Error :: ', error);
        }
    };

    useEffect(() => {
        if (userId && templateId) {
            fetchDocumentSigning(templateId).then((res) => {
                const {pdf, users, coordinates, documentTitle} = res;

                setUsers(users);
                setState((prev) => {
                    const temp = {
                        ...prev,
                        documentTitle,
                        pdf,
                        highlights: _.map(
                            coordinates,
                            ({
                                pageNumber,
                                rects,
                                boundingRect,
                                ...coordinate
                            }) => ({
                                ...coordinate,
                                position: {
                                    pageNumber,
                                    rects,
                                    ...{boundingRect}
                                },
                                editable: coordinate.userId === userId,
                                active: false,
                                save: false
                            })
                        )
                    };
                    return temp;
                });
                setFirstButton(coordinates);
            });

            // fetch(url)
            //     .then((res) => res.json())
            //     .then(({pdf, users, coordinates}) => {
            //         setUsers(users);
            //         setState({
            //             ...state,
            //             pdf: `${pdf}`,
            //             highlights: _.map(
            //                 coordinates,
            //                 ({
            //                     pageNumber,
            //                     rects,
            //                     boundingRect,
            //                     ...coordinate
            //                 }) => ({
            //                     ...coordinate,
            //                     position: {
            //                         pageNumber,
            //                         rects,
            //                         ...{boundingRect}
            //                     },
            //                     editable: coordinate.userId === userId,
            //                     active: false,
            //                     save: false
            //                 })
            //             )
            //         });
            //
            //         setButton(coordinates);
            //     });
            //
            // setBaseUrl(url);
        }
    }, [userId, templateId]);

    useEffect(async () => {
        if (token) {
            await fetchTokenValidation(token);
        }
    }, [token]);

    const scaleImage = (highlight, type, cb) => {
        const img = new Image();

        img.onload = () => {
            const rect = _.get(highlight, 'position.boundingRect');

            const height = rect.y2 - rect.y1;
            const width = rect.x2 - rect.x1;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;

            const hRatio = canvas.width / img.width;
            const vRatio = canvas.height / img.height;
            const ratio = Math.min(hRatio, vRatio);
            // eslint-disable-next-line camelcase
            const centerShift_x = (canvas.width - img.width * ratio) / 2;
            // eslint-disable-next-line camelcase
            const centerShift_y = (canvas.height - img.height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                img,
                0,
                0,
                img.width,
                img.height,
                centerShift_x,
                centerShift_y,
                img.width * ratio,
                img.height * ratio
            );

            cb(canvas.toDataURL());
        };

        img.src = type === 'reject' ? rejectBase64 : reviseBase64;
    };

    const setActiveButtons = (...keys) => {
        setState((state) => ({
            ...state,
            activeButtons: _.fromPairs(_.map(keys, (key) => [key, true]))
        }));
    };

    const setActive = (current) => {
        setState((prev) => {
            return {
                ...prev,
                highlights: _.map(prev.highlights, (highlight) => ({
                    ...highlight,
                    active: current && highlight.id === current.id
                }))
            };
        });
    };

    const clearSign = (highlight, cb) => {
        setState((prev) => ({
            ...prev,
            highlights: _.map(prev.highlights, (h) => {
                if (h.id === highlight.id) {
                    h = {
                        ...h,
                        response: {
                            ...h.response,
                            image: null,
                            reject: false,
                            revise: false,
                            approve: false
                        }
                    };
                }
                return h;
            })
        }));

        setActiveButtons('next');

        cb();
    };

    const cancelSet = () => {
        setActive(null);
        setState((prev) => ({...prev, showUpload: false}));
        setButton();
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        const drawImageScaled = (img, ctx) => {
            const {canvas} = ctx;
            const hRatio = canvas.width / img.width;
            const vRatio = canvas.height / img.height;
            const ratio = Math.min(hRatio, vRatio);
            // eslint-disable-next-line camelcase
            const centerShift_x = (canvas.width - img.width * ratio) / 2;
            // eslint-disable-next-line camelcase
            const centerShift_y = (canvas.height - img.height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                img,
                0,
                0,
                img.width,
                img.height,
                centerShift_x,
                centerShift_y,
                img.width * ratio,
                img.height * ratio
            );
        };

        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();

            img.onload = () => {
                const remaining =
                    _.filter(
                        state.highlights,
                        ({response, ...highlight}) =>
                            highlight.userId === userId &&
                            response.image === null
                    ).length === 1;

                setState((prev) => {
                    return {
                        ...prev,
                        activeButtons: {[remaining ? 'done' : 'next']: true},
                        showUpload: false,
                        highlights: _.map(prev.highlights, (highlight) => {
                            if (highlight.active) {
                                const {
                                    position: {boundingRect}
                                } = highlight;

                                const height =
                                    boundingRect.y2 - boundingRect.y1;
                                const width = boundingRect.x2 - boundingRect.x1;
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');

                                canvas.width = width;
                                canvas.height = height;

                                drawImageScaled(img, ctx);

                                highlight = {
                                    ...highlight,
                                    response: {
                                        ...highlight.response,
                                        image: canvas.toDataURL(),
                                        approve: true
                                    },
                                    active: false,
                                    save: true
                                };
                            }

                            return highlight;
                        })
                    };
                });
            };

            img.src = reader.result;
        };

        event.target.value = null;
    };

    const inputFileRef = useRef();
    const setButton = (highlights = null) => {
        if (
            _.filter(
                highlights || state.highlights,
                ({response, ...highlight}) =>
                    highlight.userId === userId && response.image === null
            ).length > 0
        ) {
            setActiveButtons('next');
        } else {
            setShowConfirmFinish(true);
            setActiveButtons('done');
        }
    };

    const setFirstButton = (highlights = null) => {
        if (
            _.filter(
                highlights || state.highlights,
                ({response, ...highlight}) =>
                    highlight.userId === userId && response.image === null
            ).length > 0
        ) {
            setActiveButtons('start');
        } else {
            setShowCompleteInfo(true);
            setActiveButtons('done');
        }
    };

    const doCancelSign = async () => {
        history.replace(RouteUrl.Login);
    };

    const onDone = async () => {
        console.log('onDone Click');
        setState((prev) => ({...prev, saving: true}));

        const highlights = _.filter(
            state.highlights,
            (highlight) => highlight.editable && highlight.save
        );

        const send = _.map(highlights, (highlight) => {
            const payload = {
                ...highlight.position,
                type: highlight.type,
                userId: highlight.userId,
                response: {
                    ...highlight.response
                }
            };

            return DocuGate.putUserSigningDocumentSigningResponse(
                templateId,
                highlight.id,
                payload,
                token
            ).then((res) => res);
        });

        Promise.all(send)
            .then((res) => {
                setState((prev) => ({
                    ...prev,
                    saving: false,
                    highlights: _.map(prev.highlights, (highlight) => ({
                        ...highlight,
                        save: false
                    }))
                }));
            })
            .finally(() => {
                toast.success('Sign process has been submitted');
                doCancelSign();
            });
    };

    const onNext = async () => {
        console.log('onNext Click');
        const highlight = _.find(
            state.highlights,
            (highlight) => !highlight.response.image && highlight.editable
        );

        console.log('highlight ::', highlight);
        setHighlightType(highlight.type);

        if (highlight) {
            if (highlight.type === 'file') {
                setState((prev) => ({
                    ...prev,
                    showUpload: true
                }));
                setActiveButtons('upload', 'reject', 'revise');
            } else if (highlight.type === 'text') {
                setActiveButtons('approve', 'reject', 'revise');
            } else {
                setActiveButtons('approve', 'reject', 'revise');
            }

            pdfRef.current.scrollTo(highlight);

            setActive(highlight);
        }
    };

    const onReject = async () => {
        console.log('onReject Click');
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Are you sure?')) return;

        const updated = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (let highlight of state.highlights) {
            if (highlight.editable) {
                const image = await new Promise((resolve, reject) => {
                    scaleImage(highlight, 'reject', (img) => resolve(img));
                });

                highlight = {
                    ...highlight,
                    active: false,
                    save: true,
                    response: {
                        ...highlight.response,
                        reject: true,
                        image
                    }
                };
            }

            updated.push(highlight);
        }

        const remaining =
            _.filter(
                state.highlights,
                ({response, ...highlight}) =>
                    highlight.userId === userId && response.image === null
            ).length === 1;

        setState((prev) => ({
            ...prev,
            activeButtons: {done: true},
            highlights: updated
        }));

        if (remaining) {
            setShowConfirmFinish(true);
        }
    };

    const onRevise = async () => {
        console.log('onRevise Click');
        const updated = [];

        // eslint-disable-next-line no-restricted-syntax
        for await (let highlight of state.highlights) {
            if (highlight.editable && highlight.active) {
                const image = await new Promise((resolve, reject) => {
                    scaleImage(highlight, 'revise', (img) => resolve(img));
                });

                highlight = {
                    ...highlight,
                    active: false,
                    save: true,
                    response: {
                        ...highlight.response,
                        revise: true,
                        image
                    }
                };
            }

            updated.push(highlight);
        }

        const remaining =
            _.filter(
                state.highlights,
                ({response, ...highlight}) =>
                    highlight.userId === userId && response.image === null
            ).length === 1;

        setState((prev) => ({
            ...prev,
            activeButtons: {
                [remaining ? 'done' : 'next']: true
            },
            highlights: updated
        }));

        if (remaining) {
            setShowConfirmFinish(true);
        }
    };

    const onApprove = async () => {
        // console.log('onApprove Click');
        if (highlightType === 'draw') {
            // console.log('drawRef ', drawRef.current);
            if (JSON.parse(drawRef.current.getSaveData()).lines.length) {
                const remaining =
                    _.filter(
                        state.highlights,
                        ({response, ...highlight}) =>
                            highlight.userId === userId &&
                            response.image === null
                    ).length === 1;

                setState(({highlights, ...prev}) => {
                    return {
                        ...prev,
                        activeButtons: {
                            [remaining ? 'done' : 'next']: true
                        },
                        highlights: _.map(highlights, (highlight) => {
                            if (highlight.active) {
                                highlight = {
                                    ...highlight,
                                    response: {
                                        ...highlight.response,
                                        image: drawRef.current.getDataURL(),
                                        approve: true
                                    },
                                    active: false,
                                    save: true
                                };
                            }

                            return highlight;
                        })
                    };
                });

                if (remaining) {
                    setShowConfirmFinish(true);
                }
            } else {
                alert('Draw first');
            }
        } else if (highlightType === 'text') {
            // console.log('textRef ', textRef.current);
            if (textRef.current.objectValue !== null) {
                const {textValue} = textRef.current.objectValue;
                const remaining =
                    _.filter(
                        state.highlights,
                        ({response, ...highlight}) =>
                            highlight.userId === userId &&
                            response.image === null
                    ).length === 1;

                setState(({highlights, ...prev}) => {
                    return {
                        ...prev,
                        activeButtons: {
                            [remaining ? 'done' : 'next']: true
                        },
                        highlights: _.map(highlights, (highlight) => {
                            if (highlight.active) {
                                console.log(' highlight ', highlight);
                                const {boundingRect} = highlight.position;

                                const heightBoundingRect =
                                    boundingRect.y2 - boundingRect.y1;
                                const widthBoundingRect =
                                    boundingRect.x2 - boundingRect.x1;

                                const canvasText =
                                    document.createElement('canvas');
                                canvasText.height = heightBoundingRect + 50;
                                canvasText.width = widthBoundingRect;

                                const context = canvasText.getContext('2d');
                                context.clearRect(
                                    0,
                                    0,
                                    canvasText.width,
                                    canvasText.height
                                );

                                // calc ratio
                                context.font = `${getFontByRatio(
                                    canvasText.width
                                )}px Arial`;
                                context.fillText(
                                    textValue,
                                    10,
                                    canvasText.height / 4
                                );

                                highlight = {
                                    ...highlight,
                                    response: {
                                        ...highlight.response,
                                        image: canvasText.toDataURL(),
                                        approve: true
                                    },
                                    active: false,
                                    save: true
                                };
                            }

                            return highlight;
                        })
                    };
                });

                if (remaining) {
                    setShowConfirmFinish(true);
                }
            } else {
                alert('Enter Text First');
            }
        } else {
            alert('UNKNOWN TYPE');
        }
    };

    return (
        <Container fluid className="bg-gray-light" style={{height: '100vh'}}>
            <TopMenu
                state={state}
                setState={setState}
                templateId={templateId}
                showConfirm={() => setShowConfirmCancel(true)}
            />
            <Row>
                <Col xl={2} lg={3} md={4} xs={4} className="mt-2">
                    <SideMenu
                        activeButtons={state.activeButtons}
                        onNext={() => onNext()}
                        onReject={() => onReject()}
                        onRevise={() => onRevise()}
                        onApprove={() => onApprove()}
                        onDone={() => onDone()}
                        saving={state.saving}
                        showUpload={() => inputFileRef.current.click()}
                    />

                    {state.showUpload && (
                        <input
                            type="file"
                            hidden
                            ref={inputFileRef}
                            accept=".png"
                            onChange={handleUpload}
                        />
                    )}
                </Col>
                <Col>
                    {state.pdf && (
                        <PDF
                            pdfUrl={state.pdf}
                            users={users}
                            highlights={state.highlights}
                            pdfRef={pdfRef}
                            drawRef={drawRef}
                            textRef={textRef}
                            clearSign={clearSign}
                            onScrollChange={cancelSet}
                        />
                    )}
                </Col>
            </Row>
            <ModalConfirm
                show={showConfirmFinish}
                title="Sign Complete"
                content={
                    <p>
                        All sign field has been completed, do you want finish
                        the sign process?
                    </p>
                }
                handleClose={() => setShowConfirmFinish(false)}
                handleSubmit={() => {
                    onDone();
                }}
            />
            <ModalConfirm
                show={showConfirmCancel}
                title="Sign Incomplete"
                content={<p>Do you want close document sign process?</p>}
                handleClose={() => setShowConfirmCancel(false)}
                handleSubmit={() => {
                    doCancelSign();
                }}
            />
            <ModalInfo
                centered
                show={showCompleteInfo}
                title="Sign has complete"
                content={
                    <p>
                        Sign process has been submitted, cannot be changed,
                        Please contact sender for more info
                    </p>
                }
                handleSubmit={() => {
                    doCancelSign();
                }}
            />
        </Container>
    );
}

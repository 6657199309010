import {combineReducers, configureStore, createStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {authSlice} from '@app/store/reducers/auth';
import {uiSlice} from '@app/store/reducers/ui';
import {subscriptionSlice} from '@store/reducers/subscription';

const persistConfig = {
    key: 'root',
    storage
};

const rootReducer = combineReducers({
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    subscription: subscriptionSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const PersistedStore = persistStore(store);

export {PersistedStore};
export default store;

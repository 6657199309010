import React, {useState} from 'react';
import {Button, Input, ContentDetail} from '@components';
import DocuGate from '@app/services/network/docugate';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import RouteUrl from '@app/routes/RouteUrl';
import {useHistory} from 'react-router-dom';

const TemplateFlowHeaderForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const handleSubmit = async (value) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.insertFlowHeader(value);
            if (response) {
                toast.success('Flow Approval Created');
                history.push(RouteUrl.TemplateFlow);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = async () => {
        history.push(RouteUrl.TemplateFlow);
    };

    const formik = useFormik({
        initialValues: {
            flowName: '',
            flowDesc: '',
            approvalLevel: '1',
            approverCount: 1
        },
        validationSchema: Yup.object({
            flowName: Yup.string().required('Required'),
            flowDesc: Yup.string().optional('Optional'),
            // approvalLevel: Yup.string().optional('Optional'),
            approverCount: Yup.number()
                .min(1, 'Must be 1 or more')
                .required('Required')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    return (
        <div>
            <ContentDetail
                isShowTitle={false}
                title="Flow Approval"
                prevTitle="Template Flow"
                prevUrl="/template-flow"
            />
            <section className="content">
                <div className="container-fluid col-6">
                    <div className="card">
                        <div className="card-header">
                            <h5>Please Define Flow Of Approval</h5>
                        </div>
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="flowName">
                                                    Flow Name
                                                </label>
                                                <Input
                                                    id="flowName"
                                                    type="text"
                                                    placeholder="Flow name"
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'flowName'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="flowName">
                                                    Flow Descriptions
                                                </label>
                                                <Input
                                                    type="text"
                                                    placeholder="Short Flow Description"
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'flowDesc'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="flowName">
                                                    Number Approve
                                                </label>
                                                <Input
                                                    type="number"
                                                    placeholder="Number Approver"
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps(
                                                        'approverCount'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <Button
                                                    theme="secondary"
                                                    className="mt-4"
                                                    block
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-6">
                                                <Button
                                                    isLoading={isLoading}
                                                    className="mt-4"
                                                    type="submit"
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TemplateFlowHeaderForm;

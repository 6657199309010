import React, {useEffect, useState} from 'react';
import DocuGate from '@app/services/network/docugate';
import {DateTime} from 'luxon';
import {Link} from 'react-router-dom';

const SubscriptionTab = ({isActive}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubscribe, setIsSubscribe] = useState(false);

    const initialState = {
        subscription: {
            plan: '',
            startDate: null,
            endDate: null
        }
    };

    const [formData, setFormData] = useState(initialState);

    const fetchProfileSubscription = async () => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getProfileSubscription();

            if (response && response.subscription) {
                setFormData(response);
                setIsSubscribe(true);
            } else {
                setIsSubscribe(false);
            }
        } catch (error) {
            const {data} = error.response;
            if (data.code === 404) {
                setIsSubscribe(false);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileSubscription();
    }, []);

    const onLoading = (
        <div className="card">
            <div className="card-header">
                <h5 className="text-bold">Your Plan</h5>
            </div>
            <div className="card-body p-3 ">
                <div className="overlay">
                    <i className="fas fa-2x fa-sync-alt fa-spin" />
                </div>
            </div>
        </div>
    );

    const onSubcribe = isSubscribe ? (
        <div className="card">
            <div className="card-header">
                <h5 className="text-bold">Your Plan</h5>
            </div>
            <div className="card-body p-3 ">
                <div className="row">
                    <div className="col-sm-4">
                        <div className="position-relative p-3 bg-gray">
                            <div className="ribbon-wrapper">
                                <div className="ribbon bg-primary">
                                    {formData.subscription.plan}
                                </div>
                            </div>
                            {formData.subscription.plan} <br />
                            <small>
                                Expiration Date :{' '}
                                {DateTime.fromISO(
                                    formData.subscription.endDate
                                ).toFormat('dd LLL yyyy')}
                            </small>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-4">
                        <h6>We hope you enjoying our product.</h6>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="card">
            <div className="card-header">
                <h5 className="text-bold">Your Plan</h5>
            </div>
            <div className="card-body p-3 ">
                <div className="row mt-3">
                    <div className="col-sm-4">
                        <Link to="/package" className="btn btn-primary">
                            Subcribe Now
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            {isLoading ? onLoading : onSubcribe}
        </div>
    );
};

export default SubscriptionTab;

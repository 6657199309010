import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    package: {
        packageId: '',
        plan: ''
    },
    subscription: {
        startDate: '',
        endDate: ''
    }
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        loadSubscription: (state, {payload}) => {
            const {packageId, plan} = payload.subscription;
            state.package = {packageId, plan};

            const {startDate, endDate} = payload.subscription;
            state.subscription = {
                startDate,
                endDate
            };
        },
        removeSubscription: (state) => {
            state.package = initialState.package;
            state.subscription = initialState.subscription;
        }
    }
});

export const {setSubscription, removeSubscription, loadSubscription} =
    subscriptionSlice.actions;

export default subscriptionSlice.reducer;

/*
  https://gitlab.com/witdiarta/annotation
 */
import _ from 'lodash';
import {Icon, Menu, Container, Loader, Dimmer} from 'semantic-ui-react';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useRouter} from '@app/hooks/use-router';
import DocuGate from '@app/services/network/docugate';
import {toast} from 'react-toastify';
import RouteUrl from '@app/routes/RouteUrl';
import {useHistory} from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ButtonIcon, Button, PageLoading, ModalConfirm} from '@components';
import ModalInfo from '@app/components/modal/ModalInfo';

const {
    PdfLoader,
    PdfHighlighter,
    AreaHighlight,
    Popup
} = require('react-pdf-highlighter');

function HighlightTypeSwitcher(type) {
    switch (type) {
        case 'draw':
            return 'pencil alternate';
        case 'file':
            return 'upload';
        case 'text':
            return 'edit outline';
        default:
            return 'eye slash outline';
    }
}

function HighlightTypeSwitcherCode(code) {
    switch (code) {
        case 0:
            return 'draw';
        case 1:
            return 'file';
        case 2:
            return 'text';
        default:
            return 'draw';
    }
}

function SideMenu({state, setState, templateId}) {
    // (e,{index}) => {
    const setUser = (userId) => {
        setState({
            ...state,
            // activeUser: _.find(state.users, (user) => +user.id === +index)
            activeUser: _.find(state.users, (user) => user.id === userId) // modified
        });
    };
    const setTool = (e, {index}) => setState({...state, activeTool: index});
    const save = async (e) => {
        const highlights = _.get(state, 'highlights', []);
        // const apiUrl = `${baseUrl}/templates/${templateId}/coordinates`;
        // const apiUrl = 'http://localhost:3000/v1/template/annotate';

        setState((prev) => ({
            ...prev,
            saving: true
        }));

        const coordinates = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (const highlight of highlights) {
            if (highlight.save) {
                let payload = {};
                if (highlight.method === 'POST') {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        userId: highlight.userId,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                } else {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                }

                const data = await DocuGate.crudDocumentSigningCoordinate(
                    highlight.method,
                    templateId,
                    payload
                );

                if (!highlight.deleted) {
                    coordinates.push(data);
                }
            } else if (!highlight.save && !highlight.deleted) {
                coordinates.push(highlight);
            }
        }

        setState((prev) => ({
            ...prev,
            saving: false,
            activeUser: null,
            activeTool: null,
            highlights: _.map(coordinates, (coordinate) => ({
                position: coordinate.position || coordinate,
                // eslint-disable-next-line no-underscore-dangle
                _id: coordinate._id || coordinate.id,
                method: 'POST',
                save: false,
                id: String(Math.random()).slice(2),
                color:
                    coordinate.color ||
                    _.find(prev.users, {id: coordinate.userId}).color,
                type: coordinate.type
            }))
        }));
    };

    return (
        <div>
            <Menu vertical inverted className="w-100">
                <Menu.Item header className="tx-left">
                    Users
                </Menu.Item>
                {state.users.map((user, index) => (
                    <Menu.Item
                        index={index}
                        name={user.name}
                        active={_.get(state.activeUser, 'id') === user.id}
                        onClick={() => setUser(user.id)}
                        key={user.id}
                        color={user.color}
                        className="d-flex align-items-center"
                    >
                        <Icon
                            circular
                            inverted
                            color={user.color}
                            name="user"
                            className="ms-0 me-2"
                        />
                        <span className="flex-fill ml-2">{user.name}</span>
                    </Menu.Item>
                ))}
            </Menu>

            <Menu inverted vertical className="mt-4 w-100">
                <Menu.Item header className="text-start">
                    Tools
                </Menu.Item>
                <Menu.Item
                    index={0}
                    active={state.activeTool === 0}
                    onClick={setTool}
                    className="d-flex align-items-center"
                    color="green"
                >
                    <Icon
                        circular
                        inverted
                        color="green"
                        name="pencil alternate"
                        className="ms-0 me-2 mr-2"
                    />
                    Signature Field
                </Menu.Item>
                <Menu.Item
                    index={1}
                    active={state.activeTool === 1}
                    onClick={setTool}
                    className="d-flex align-items-center"
                    color="orange"
                >
                    <Icon
                        circular
                        inverted
                        color="orange"
                        name="upload"
                        className="ms-0 me-2 mr-2"
                    />
                    Upload Image
                </Menu.Item>
                <Menu.Item
                    index={2}
                    active={state.activeTool === 2}
                    onClick={setTool}
                    className="d-flex align-items-center"
                    color="blue"
                >
                    <Icon
                        circular
                        inverted
                        color="blue"
                        name="edit outline"
                        className="ms-0 me-2 mr-2"
                    />
                    Text Field
                </Menu.Item>
            </Menu>
        </div>
    );
}

function TopMenu({state, setState, templateId, showConfirm}) {
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const doSave = async (e) => {
        setIsLoadingSave(true);
        const highlights = _.get(state, 'highlights', []);

        setState((prev) => ({
            ...prev,
            saving: true
        }));

        const coordinates = [];
        // eslint-disable-next-line no-restricted-syntax
        for await (const highlight of highlights) {
            if (highlight.save) {
                let payload = {};
                if (highlight.method === 'POST') {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        userId: highlight.userId,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                } else {
                    payload = {
                        ...highlight.position,
                        type: highlight.type,
                        response: {
                            reject: false,
                            revise: false,
                            approve: false,
                            image: null
                        }
                    };
                }

                const data = await DocuGate.crudDocumentSigningCoordinate(
                    highlight.method,
                    templateId,
                    payload
                );

                if (!highlight.deleted) {
                    coordinates.push(data);
                }
            } else if (!highlight.save && !highlight.deleted) {
                coordinates.push(highlight);
            }
        }

        setState((prev) => ({
            ...prev,
            saving: false,
            activeUser: null,
            activeTool: null,
            highlights: _.map(coordinates, (coordinate) => ({
                position: coordinate.position || coordinate,
                // eslint-disable-next-line no-underscore-dangle
                _id: coordinate._id || coordinate.id,
                method: 'POST',
                save: false,
                id: String(Math.random()).slice(2),
                color:
                    coordinate.color ||
                    _.find(prev.users, {id: coordinate.userId}).color,
                type: coordinate.type
            }))
        }));
        setIsLoadingSave(false);
        showConfirm();
    };

    return (
        <Menu attached="top" borderless>
            <Menu.Item header>
                <h3>{state.documentTitle}</h3>
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item>
                    <Button
                        icon="save"
                        theme="outline-primary"
                        isLoading={isLoadingSave}
                        onClick={doSave}
                    >
                        Save And Close
                    </Button>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
}

function PDF({state, setState}) {
    const history = useHistory();

    const {highlights, activeTool, activeUser} = state;
    const ref = useRef({
        enable: false
    });
    const [pdf, setPdf] = useState();
    const [showError, setShowError] = useState(false);

    const finishSelect = useCallback(
        (position, content, hideTipAndSelection, transformSelection) => {
            if (content.image) {
                setState((state) => ({
                    ...state,
                    activeUser: null,
                    activeTool: null,
                    highlights: [
                        ...state.highlights,
                        {
                            id: String(Math.random()).slice(2),
                            save: true,
                            method: 'POST',
                            content,
                            position,
                            color: state.activeUser.color,
                            userId: state.activeUser.id,
                            type: HighlightTypeSwitcherCode(state.activeTool)
                        }
                    ]
                }));
            }

            hideTipAndSelection();
        },
        [activeTool, activeUser]
    );

    const updateHighlight = useCallback(
        (highlightId, position, content) => {
            setState((state) => ({
                ...state,
                highlights: highlights.map((h) => {
                    const {
                        id,
                        position: originalPosition,
                        content: originalContent,
                        ...rest
                    } = h;
                    if (h.id === highlightId) {
                        return {
                            ...rest,
                            id,
                            // eslint-disable-next-line no-underscore-dangle
                            method: h._id ? 'PUT' : 'POST',
                            save: true,
                            position: {...originalPosition, ...position},
                            content: {...originalContent, ...content}
                        };
                    }

                    return h;
                })
            }));
        },
        [highlights, setState]
    );

    const removeHighlight = useCallback(
        (highlight) => {
            return () => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm('Are you sure!') === true) {
                    setState({
                        ...state,
                        highlights: _.map(highlights, (h) => {
                            if (h.id === highlight.id) {
                                return {
                                    ...h,
                                    deleted: true,
                                    // eslint-disable-next-line no-underscore-dangle
                                    save: !!h._id,
                                    method: 'DELETE'
                                };
                            }

                            return h;
                        })
                    });

                    highlight.hideTip();
                }
            };
        },
        [highlights]
    );

    const HighlightPopup = useCallback(
        (highlight) => {
            return (
                <ButtonIcon
                    icon="delete"
                    onClick={removeHighlight(highlight)}
                />
            );
        },
        [highlights]
    );

    useEffect(() => {
        ref.current = {
            ...ref.current,
            enable: activeTool !== null && activeUser !== null,
            highlights
        };

        setPdf(
            <div
                style={{
                    height: '92vh',
                    position: 'relative'
                }}
            >
                <PdfLoader
                    url={state.pdf}
                    beforeLoad={<PageLoading />}
                    onError={() => setShowError(true)}
                >
                    {(pdfDocument) => (
                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            highlights={_.filter(highlights, (h) => !h.deleted)}
                            scrollRef={() => {
                                // console.log('scroll');
                            }}
                            enableAreaSelection={() => ref.current.enable}
                            onSelectionFinished={finishSelect}
                            highlightTransform={(
                                highlight,
                                index,
                                setTip,
                                hideTip,
                                viewportToScaled,
                                screenshot,
                                isScrolledTo
                            ) => {
                                return (
                                    <Popup
                                        popupContent={
                                            <HighlightPopup
                                                {...{...highlight, hideTip}}
                                            />
                                        }
                                        onMouseOver={(content) =>
                                            setTip(
                                                highlight,
                                                (highlight) => content
                                            )
                                        }
                                        onMouseOut={hideTip}
                                        key={index}
                                    >
                                        <AreaHighlight
                                            key={index}
                                            isScrolledTo={isScrolledTo}
                                            highlight={highlight}
                                            onChange={(boundingRect, e) => {
                                                updateHighlight(
                                                    highlight.id,
                                                    {
                                                        boundingRect:
                                                            viewportToScaled(
                                                                boundingRect
                                                            )
                                                    },
                                                    {
                                                        image: screenshot(
                                                            boundingRect
                                                        )
                                                    }
                                                );
                                            }}
                                            className={`ui inverted ${highlight.color} segment`}
                                        >
                                            <Icon
                                                circular
                                                inverted
                                                color="black"
                                                name={HighlightTypeSwitcher(
                                                    highlight.type
                                                )}
                                                className="ms-0 me-2"
                                            />
                                        </AreaHighlight>
                                    </Popup>
                                );
                            }}
                        />
                    )}
                </PdfLoader>
            </div>
        );
    }, [activeTool, activeUser, highlights, finishSelect]);

    return (
        <div>
            <div>
                {pdf || (
                    <Dimmer active inverted>
                        <Loader>Preparing Files</Loader>
                    </Dimmer>
                )}
            </div>
            <ModalInfo
                centered
                show={showError}
                title="Error Opening Document"
                content={<p>There was an error when opening the document</p>}
                handleSubmit={() => {
                    history.push(RouteUrl.Documents);
                }}
            />
        </div>
    );
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default function DocumentSign() {
    const router = useRouter();
    const history = useHistory();

    const {templateId} = router.query;
    const [showConfirm, setShowConfirm] = useState(false);
    const [state, setState] = useState({
        documentTitle: '',
        pdf: null,
        users: [],
        activeUser: null,
        activeTool: null,
        saving: false,
        highlights: []
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitConfirm = () => {
        // console.log('handleSubmitConfirm');
        history.push(RouteUrl.Documents);
    };

    const returnPageDocument = () => {
        history.push(RouteUrl.Documents);
    };

    // eslint-disable-next-line consistent-return
    const fetchDocumentSigning = async (documentId) => {
        try {
            setIsLoading(true);
            const responseModel = {
                documentTitle: '',
                pdf: '',
                id: '',
                coordinates: [],
                users: []
            };

            const response = await DocuGate.getDocumentSigning(documentId);

            if (response) {
                const {document, users, coordinates} = response;
                responseModel.id = document.Id;
                responseModel.documentTitle = document.DocName;
                responseModel.pdf = `${process.env.REACT_APP_GATEKEEPER_URL}${document.DocPath}`;
                responseModel.users = users;
                responseModel.coordinates = coordinates;
            } else {
                toast.error('Document Not Found');
                returnPageDocument();
            }
            setIsLoading(false);
            // console.log('responseModel ::', responseModel);
            return responseModel;
        } catch (error) {
            // console.log('fetchDocumentSigning Error :: ', error);
            returnPageDocument();
            setIsLoading(false);
            toast.error('Document Not Found');
        }
    };

    useEffect(async () => {
        if (templateId) {
            fetchDocumentSigning(templateId).then((res) => {
                setState((prev) => {
                    const {pdf, users, coordinates, documentTitle} = res;

                    return {
                        ...prev,
                        documentTitle,
                        pdf,
                        users,
                        highlights: _.map(coordinates, (coordinate) => ({
                            position: coordinate,
                            _id: coordinate.id,
                            method: 'POST',
                            save: false,
                            id: String(Math.random()).slice(2),
                            color: _.find(users, {id: coordinate.userId}).color,
                            type: coordinate.type
                        }))
                    };
                });
            });
        }
    }, [templateId]);

    return (
        <Container fluid className="bg-gray-light" style={{height: '100vh'}}>
            <TopMenu
                state={state}
                setState={setState}
                templateId={templateId}
                showConfirm={() => setShowConfirm(true)}
            />
            <Row>
                <Col xl={2} lg={3} md={4} xs={4} className="mt-2">
                    <SideMenu
                        state={state}
                        setState={setState}
                        templateId={templateId}
                    />
                </Col>
                <Col>
                    {state.pdf && <PDF state={state} setState={setState} />}
                </Col>
            </Row>
            <ModalConfirm
                show={showConfirm}
                title="Document updated successfully"
                content={<p>Do you want back to document menu?</p>}
                handleClose={() => setShowConfirm(false)}
                handleSubmit={handleSubmitConfirm}
            />
        </Container>
    );
}

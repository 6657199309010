import React, {useEffect, useRef, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import PropTypes from 'prop-types';
import paginator from './Paginator';

/*
 * Pagination with props
 */
const PaginationComponent = ({
    onPageChanges,
    recordsPerPage,
    recordsTotal,
    isLoadingData
}) => {
    // Hooks
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pager, setPager] = useState({});
    const [totalRecordsPage, setTotalRecordsPage] = useState(0);
    const currentPageRef = useRef(1);

    /*
     * Fetch data from API
     * Append query params if any
     * API call with GET
     */
    const getData = (pageNumber = 1) => {
        setCurrentPage(Number(pageNumber));
        setTotalRecordsPage(Math.ceil(recordsTotal / recordsPerPage));
        setPagination(recordsTotal, pageNumber, recordsPerPage);
    };

    /*
     * Set pagination data and pager data
     */
    const setPagination = (
        totalCount = 0,
        pageNumber = 1,
        recordsPerPage = 0
    ) => {
        setTotalCount(Number(totalCount));
        const pData = paginator(totalCount, pageNumber, recordsPerPage);
        setPager({...pData});
    };

    const pageChanges = (pageNumber = 1) => {
        getData(pageNumber);
        onPageChanges(pageNumber);
    };

    /*
     * Watch reloadApi flag
     */
    useEffect(() => {
        if (!isLoadingData) {
            getData(currentPage);
        }
    }, [isLoadingData]);

    /*
     * Component initiated
     */
    useEffect(() => {
        getData(currentPage);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {
        currentPageRef.current = currentPage;
    }, [currentPage]);

    /*
     *  Initial Render
     *  Watch recordsPerPage
     */
    useEffect(() => {
        getData(currentPage);
    }, [recordsPerPage]);

    /*
     * Render
     */
    return (
        <div>
            {totalCount > 0 && (
                <nav aria-label="Pagination" className="pages">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li
                            key={uuidv4()}
                            className={
                                currentPage === 1
                                    ? 'disabled page-item'
                                    : 'page-item'
                            }
                        >
                            <a
                                href="#!"
                                className="page-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pageChanges(currentPageRef.current - 1);
                                }}
                            >
                                Prev
                            </a>
                        </li>
                        {pager.pages &&
                            pager.pages.map((page = 0, index = 0) => {
                                return (
                                    <li
                                        key={uuidv4()}
                                        className={
                                            currentPage === page
                                                ? 'active page-item'
                                                : 'page-item'
                                        }
                                    >
                                        <a
                                            className="page-link"
                                            href="#!"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                pageChanges(page);
                                            }}
                                        >
                                            {page}
                                        </a>
                                    </li>
                                );
                            })}
                        <li
                            key={uuidv4()}
                            className={
                                currentPage + 1 > totalRecordsPage
                                    ? 'disabled page-item'
                                    : 'page-item'
                            }
                        >
                            <a
                                className="page-link"
                                href="#!"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pageChanges(currentPageRef.current + 1);
                                }}
                            >
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            )}
            {totalCount <= 0 && (
                <nav aria-label="Pagination" className="pages">
                    <ul className="pagination pagination-sm m-0 float-right">
                        <li key={uuidv4()} className="disabled page-item">
                            <a href="#!" className="page-link">
                                Prev
                            </a>
                        </li>
                        <li key={uuidv4()} className=" disabled page-item">
                            <a className="page-link" href="#!">
                                1
                            </a>
                        </li>
                        <li key={uuidv4()} className="disabled page-item">
                            <a className="page-link" href="#!">
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
};

PaginationComponent.propTypes = {
    onPageChanges: PropTypes.func.isRequired,
    recordsPerPage: PropTypes.number.isRequired,
    recordsTotal: PropTypes.number.isRequired,
    isLoadingData: PropTypes.bool.isRequired
};

export default PaginationComponent;

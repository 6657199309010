const RouteUrl = {
    Subscription: '/subscription',
    SubscriptionPlan: '/subscription-plan',
    Documents: '/document',
    DocumentSigning: '/annotate?templateId=:templateId',
    DocumentGenerate: '/generate?templateId=:templateId',
    DocumentUserSign: '/user-sign?templateId=:templateId&userId:user',
    DocumentsUpload: '/document/upload',
    Profile: '/profile',
    Package: '/package',
    Invoice: '/invoice',
    TemplateFlow: '/template-flow',
    TemplateFlowHeaderForm: '/template-flow/header',
    TemplateFlowDetail: '/template-flow/header/:headerId/detail',
    TemplateFlowDetailFrom: '/template-flow/header/:headerId/detail/:detailId',
    Archives: '/archives',
    Trash: '/trash',
    UserSigning: '/UserSign?templateId=:templateId&userId=:userId',
    Login: '/login'
};

export default RouteUrl;

import React from 'react';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';
import RouteUrl from '@app/routes/RouteUrl';

export const MENU = [
    {
        name: 'menusidebar.label.sign',
        path: ``,
        icon: '',
        menuType: 'header'
    },
    {
        name: 'menusidebar.label.documents',
        path: `${RouteUrl.Documents}`,
        icon: 'fa fa-file-alt',
        menuType: 'item'
    },
    {
        name: 'menusidebar.label.archive',
        path: `${RouteUrl.Archives}`,
        icon: 'fa fa-folder',
        menuType: 'item'
    },
    {
        name: 'menusidebar.label.templateFlow',
        path: `${RouteUrl.TemplateFlow}`,
        icon: 'fa fa-bullseye',
        menuType: 'item'
    },
    // {
    //     name: 'menusidebar.label.trash',
    //     path: `${RouteUrl.Trash}`,
    //     icon: 'fa fa-trash-alt',
    //     menuType: 'item'
    // },
    {
        name: 'menusidebar.label.setting',
        path: ``,
        icon: '',
        menuType: 'header'
    },
    {
        name: 'menusidebar.label.subscription',
        path: `${RouteUrl.SubscriptionPlan}`,
        icon: 'fa fa-tasks',
        menuType: 'item'
    }
];

export const MENUTEST = [
    {
        name: 'menusidebar.label.flowheader',
        path: '/FlowHeaderManagement',
        icon: 'fa-folder'
    },
    {
        name: 'menusidebar.label.flowdetail',
        path: '/FlowDetailManagement',
        icon: 'fa-folder'
    },
    {
        name: 'upload',
        path: '/upload',
        icon: 'fa-folder'
    },
    {
        name: 'User Document Sign',
        path: '/user-sign?templateId=188d37af-62e8-4ddc-996e-4367de90be0e&userId=9089c4e4-c7de-4f23-acf4-fef1c6019fac',
        icon: 'fa-folder'
    },
    {
        name: 'User Sign',
        path: '/usersign?templateId=2738e4f1-b290-49a3-818b-659334f85084&userId=3c24fb56-ecd8-4b96-8533-207be0dd1a02',
        // path: '/annotate?templateId=2738e4f1-b290-49a3-818b-659334f85084',
        icon: 'fa-folder'
    }
];

const MenuSidebar = () => {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link bg-white">
                <img
                    src="/alca_logo.png"
                    alt="ALCASign"
                    className="brand-image img-circle elevation-3"
                    style={{opacity: '.8'}}
                />
                <span className="brand-text font-weight-bold">ALCASign</span>
            </Link>
            <div className="sidebar">
                <nav className="mt-2" style={{overflow: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                    >
                        {MENU.map((menuItem) => (
                            <MenuItem key={menuItem.name} menuItem={menuItem} />
                        ))}
                    </ul>
                </nav>
                {/* <nav className="mt-2" style={{overflow: 'hidden'}}> */}
                {/*    <ul */}
                {/*        className="nav nav-pills nav-sidebar flex-column" */}
                {/*        role="menu" */}
                {/*    > */}
                {/*        {MENUTEST.map((menuItem) => ( */}
                {/*            <MenuItem key={menuItem.name} menuItem={menuItem} /> */}
                {/*        ))} */}
                {/*    </ul> */}
                {/* </nav> */}
            </div>
        </aside>
    );
};

export default MenuSidebar;

import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {Button, Input} from '@components';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import DocuGate from '@app/services/network/docugate';

const ForgotPassword = () => {
    const history = useHistory();
    const [t] = useTranslation();
    const [isAuthLoading, setAuthLoading] = useState(false);

    const requestPassword = async (email) => {
        try {
            setAuthLoading(true);
            await DocuGate.forgotPassword(email);
            toast.success('Request password is sent!');
            history.push('/login');
        } catch (error) {
            setAuthLoading(false);
            toast.error(
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    'Failed'
            );
        } finally {
            setAuthLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required')
        }),
        onSubmit: (values) => {
            requestPassword(values.email);
        }
    });

    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <b>DOCU</b>
                        <span>Flow</span>
                    </Link>
                </div>
                <div className="card-body">
                    <p className="login-box-msg">
                        {t('recover.forgotYourPassword')}
                    </p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <Input
                                    icon={faEnvelope}
                                    placeholder="Email"
                                    type="email"
                                    formik={formik}
                                    formikFieldProps={formik.getFieldProps(
                                        'email'
                                    )}
                                />
                                <Button
                                    className="mt-3"
                                    type="submit"
                                    isLoading={isAuthLoading}
                                    block
                                >
                                    {t('recover.requestNewPassword')}
                                </Button>
                            </div>
                        </div>
                    </form>
                    <p className="mt-3 mb-1">
                        <Link to="/login">
                            {t('login.button.signIn.label')}
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const ModalSpinner = ({isLoading, show, children, ...otherProps}) => {
    let spinnerTemplate;
    if (isLoading) {
        spinnerTemplate = (
            <Spinner
                className="ml-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        );
    }
    return (
        <Modal {...otherProps} show={show}>
            <Modal.Body className="text-center">
                {children}
                {spinnerTemplate}
            </Modal.Body>
        </Modal>
    );
};

export default ModalSpinner;

/*
  https://gitlab.com/witdiarta/annotation
 */
import {useRouter} from '@app/hooks/use-router';
import {useEffect, useState} from 'react';
import {Segment, Dimmer, Loader} from 'semantic-ui-react';
import {PDFDocument} from 'pdf-lib';
import DocuGate from '@app/services/network/docugate';
import {toast} from 'react-toastify';

export default function GeneratePdf() {
    const router = useRouter();
    const {token} = router.query;

    const [result, setResult] = useState();
    const [documentId, setDocumentId] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line consistent-return
    const fetchDocumentSigning = async (documentId) => {
        try {
            setIsLoading(true);
            const responseModel = {
                documentTitle: '',
                pdf: '',
                id: '',
                coordinates: [],
                users: []
            };

            const response = await DocuGate.getUserSigningDocumentSigning(
                documentId,
                token
            );

            if (response) {
                const {document, users, coordinates} = response;

                responseModel.id = document.Id;
                responseModel.documentTitle = document.DocName;
                const documentUrl = `${
                    process.env.REACT_APP_GATEKEEPER_URL
                }${document.DocPath.replace('asset', 'user-sign')}`;
                responseModel.pdf = documentUrl;
                responseModel.users = users;
                responseModel.coordinates = coordinates;
            } else {
                toast.error('Document Not Found');
            }
            setIsLoading(false);
            return responseModel;
        } catch (error) {
            setIsLoading(false);
            toast.error('Document Not Found');
        }
    };

    const fetchTokenValidation = async (token) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getUserSigningValidation(token);

            if (response) {
                const {documentID} = response;
                setDocumentId(documentID);
            } else {
                toast.error('Expired Link');
            }
            setIsLoading(false);
        } catch (error) {
            console.log('fetchTokenValidation Error :: ', error);
        }
    };

    useEffect(() => {
        if (documentId) {
            // eslint-disable-next-line no-inner-declarations
            async function generate() {
                const {coordinates, pdf} = await fetchDocumentSigning(
                    documentId
                );

                const getPdf = await fetch(`${pdf}`).then((res) =>
                    res.arrayBuffer()
                );

                const pdfDoc = await PDFDocument.load(getPdf);

                // eslint-disable-next-line no-restricted-syntax
                for await (const highlight of coordinates) {
                    const page = pdfDoc.getPage(highlight.pageNumber - 1);
                    const {boundingRect, response} = highlight;

                    const widthScale = page.getWidth() / boundingRect.width;
                    const heightScale = page.getHeight() / boundingRect.height;
                    const height =
                        (boundingRect.y2 - boundingRect.y1) * heightScale;
                    const width =
                        (boundingRect.x2 - boundingRect.x1) * widthScale;

                    if (response.image) {
                        const image = await pdfDoc.embedPng(response.image);

                        const opts = {
                            x: boundingRect.x1 * widthScale,
                            y: page.getHeight() - boundingRect.y2 * heightScale,
                            width,
                            height
                        };

                        page.drawImage(image, opts);
                    }
                }

                const pdfBytes = await pdfDoc.saveAsBase64({dataUri: true});

                setResult(pdfBytes);
            }

            generate();
        }
    }, [documentId]);

    useEffect(async () => {
        if (token) {
            await fetchTokenValidation(token);
        }
    }, [token]);

    return (
        <div>
            <Segment className="vh-100">
                <Dimmer active={!result}>
                    <Loader>Generating file</Loader>
                </Dimmer>

                {result && (
                    // eslint-disable-next-line jsx-a11y/iframe-has-title
                    <iframe
                        style={{width: '100vw', height: '100vh'}}
                        src={result}
                    />
                )}
            </Segment>
        </div>
    );
}

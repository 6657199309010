const addScript = (id, src) => {
    return new Promise((resolve, reject) => {
        try {
            const element = document.getElementById(id);
            if (element) {
                return resolve(true);
            }
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('id', id);
            script.setAttribute('src', src);
            script.setAttribute('crossorigin', 'anonymous"');
            script.addEventListener('load', resolve);
            script.addEventListener('error', () =>
                reject(new Error(`Error loading ${id}.`))
            );
            script.addEventListener('abort', () =>
                reject(new Error(`${id}  loading aborted.`))
            );
            document.getElementsByTagName('head')[0].appendChild(script);
        } catch (error) {
            return Promise.reject(error);
        }
        return Promise.resolve('Success');
    });
};

const isBrowser = typeof window === 'object';

const addFacebookScript = async () => {
    const id = 'facebookAuth';
    const src = 'https://connect.facebook.net/en_US/sdk.js';
    return addScript(id, src);
};

const addGoogleScript = async () => {
    const id = 'googleAuth';
    const src = 'https://apis.google.com/js/api.js';
    return addScript(id, src);
};

// const addLinkedInScript = async () => {
//     const id = 'linkedinAuth';
//     const src = 'https://platform.linkedin.com/in.js?async=true';
//     return addScript(id, src);
// };

const getAuthInstance = (params) => {
    return new Promise((res) => {
        window.gapi.load('auth2', () => {
            if (!window.gapi.auth2.getAuthInstance()) {
                window.gapi.auth2.init(params);
            }
            res(window.gapi.auth2.getAuthInstance());
        });
    });
};

const getGoogleAuthInstance = async (clientID) => {
    try {
        await addGoogleScript();
        const params = {
            client_id: clientID,
            scope: 'openid profile email',
            cookie_policy: 'single_host_origin',
            fetch_basic_profile: true
        };
        const instance = await getAuthInstance(params);
        return instance;
    } catch (error) {
        throw new Error('ADD_SCRIPT_ERROR');
    }
};

const getFacebookStatus = () => {
    return new Promise((resolve) => {
        window.FB.getLoginStatus((data) => {
            if (data.status === 'connected') {
                resolve(data);
            }
            resolve(null);
        });
    });
};

const loginFacebook = () => {
    return new Promise((resolve, reject) => {
        window.FB.login(
            (data) => {
                if (data.status === 'connected') {
                    resolve(data);
                }
                reject(new Error('FACEBOOK_ERROR'));
            },
            {scope: 'email'}
        );
    });
};

const getFacebookAccessToken = async (appId) => {
    await addFacebookScript();

    const params = {
        appId,
        version: 'v3.2',
        cookie: false,
        xfbml: false
    };
    window.FB.init(params);

    let data = await getFacebookStatus();

    if (!data) {
        data = await loginFacebook();
    }
    return data.authResponse.accessToken;
};

export {isBrowser, getGoogleAuthInstance, getFacebookAccessToken};

import React, {useCallback, useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import {Link, useHistory} from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {PageLoading} from '@components';

const RegisterSuccess = () => {
    const history = useHistory();
    const [progressValue, setProgressValue] = useState(0);
    const [showPageLoading, setShowPageLoading] = useState(false);

    const goToPageAndClear = async (_path) => {
        history.replace(_path);
    };

    useEffect(() => {
        if (progressValue >= 100) {
            setShowPageLoading(true);
            setTimeout(() => {
                goToPageAndClear('/');
            }, 1000);
        } else {
            setTimeout(() => {
                setProgressValue(progressValue + 10);
            }, 300);
        }
    }, [progressValue]);

    document.getElementById('root').classList = 'hold-transition register-page';

    const pages = useCallback(() => {
        if (showPageLoading) {
            return <PageLoading />;
        }
        return (
            <>
                <Card>
                    <Card.Header>
                        <div className="card-header text-center">
                            <Link to="/" className="h1">
                                <b>ALCA</b>
                                <span>Sign</span>
                            </Link>
                        </div>
                        <ProgressBar now={progressValue} />
                    </Card.Header>
                    <Card.Body>
                        <Container className="my-3">
                            <Row>
                                <Col className="text-center">
                                    <h3 className="font-weight-bold mb-2">
                                        Thank You For Register
                                    </h3>
                                    <h5 className="mb-4">
                                        Increase productivity and improve
                                        collaboration with us
                                    </h5>
                                    <span>
                                        In a moment you will be redirected to
                                        the dashboard page.
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        );
    });

    return <div className="section">{pages()}</div>;
};

export default RegisterSuccess;

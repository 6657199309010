import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {ModalSpinner} from '@components';
import DocuGate from '@app/services/network/docugate';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {dateConvertPattern} from '@app/helper/formatter';

const SubscriptionPlan = () => {
    const [termModalShow, setTermModalShow] = useState(false);
    const [paymentMethodModalShow, setPaymentMethodModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pleaseWaitShow, setPleaseWaitShow] = useState(false);
    const [activeKey, setActiveKey] = useState('');
    const [activePaymentMethod, setActivePaymentMethod] = useState('');
    const [packageName, setPackageName] = useState('');
    const [basicPrice, setBasicPrice] = useState('');
    const [unpaidBilling, setUnpaidBilling] = useState(null);
    const [billing, setBilling] = useState(null);
    const [paymentMethodSelection, setPaymentMethodSelection] = useState([]);
    const [disableSubscription, setDisableSubscription] = useState(true);

    const ArrayPaymentConfig = [
        {
            typeName: 'Credit Card',
            codeList: ['VC']
        },
        {
            typeName: 'Virtual Account',
            codeList: [
                'BC',
                'M2',
                'VA',
                'I1',
                'B1',
                'BT',
                'A1',
                'AG',
                'NC',
                'BR'
            ]
        },
        {
            typeName: 'E-Wallet',
            codeList: ['OV', 'SA', 'LF', 'LA', 'DA', 'SL', 'OL']
        },
        {
            typeName: 'QRIS',
            codeList: ['SP', 'LQ', 'NQ']
        },
        {
            typeName: 'Ritel',
            codeList: ['FT', 'A2', 'IR']
        }
    ];

    const subscriptionStore = useSelector(
        (state) => state.subscription.subscription
    );
    const packageStore = useSelector((state) => state.subscription.package);

    const getBasicPriceAmount = (basicPrice, term) => {
        const calculatedPrice = basicPrice * term;
        return `Rp ${calculatedPrice.toLocaleString()}`;
    };

    const getActiveKeyToNumber = (term) => {
        let monthValue = 1;

        switch (term) {
            case 'monthly':
                monthValue = 1;
                break;
            case 'quarterly':
                monthValue = 3;
                break;
            case 'semiannually':
                monthValue = 6;
                break;
            case 'annually':
                monthValue = 12;
                break;
            default:
                monthValue = 1;
                break;
        }

        return monthValue;
    };

    const HandlePayment = async () => {
        try {
            setTermModalShow(false);
            setPaymentMethodModalShow(false);
            setPleaseWaitShow(true);

            const request = {
                packageId: packageName,
                term: getActiveKeyToNumber(activeKey),
                paymentMethod: activePaymentMethod
            };
            const response = await DocuGate.postSubscribePackages(request);

            if (response.invoiceUrl) {
                window.location.replace(response.invoiceUrl);
            }
        } catch (error) {
            setPleaseWaitShow(false);
            toast.error(error);
        }
    };

    const HandlePaymentMethod = async () => {
        try {
            setTermModalShow(false);
            setPaymentMethodModalShow(true);
        } catch (error) {
            toast.error(error);
        }
    };

    const ModalSpinnerPleaseWait = () => {
        return (
            <ModalSpinner centered show={pleaseWaitShow} isLoading="true">
                <div className="text-center">
                    <p>You will be redirected to Payment Page</p>
                    <p className="text-bold">Please Wait</p>
                </div>
            </ModalSpinner>
        );
    };

    const handleSelectPackage = (packageName) => {
        switch (packageName) {
            case 'STARTER':
                setPackageName('STARTER');
                setBasicPrice(50000);
                setTermModalShow(true);
                break;
            case 'PRO':
                setPackageName('PRO');
                setBasicPrice(100000);
                setTermModalShow(true);
                break;
            default:
                break;
        }
    };

    const TermSelectionModal = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                onHide={() => {
                    setTermModalShow(false);
                    setActiveKey('');
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Billing Frequency
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <ListGroup.Item
                            action
                            className="d-flex justify-content-between align-items-start"
                            active={activeKey === 'monthly'}
                            onClick={() => setActiveKey('monthly')}
                        >
                            <div className="ms-2 me-auto">
                                <div className="text-bold my-2">
                                    <span style={{fontSize: '18px'}}>
                                        1 Month
                                    </span>
                                </div>
                                <div className="text-bold text-dark">
                                    {getBasicPriceAmount(basicPrice, 1)}
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="d-flex justify-content-between align-items-start"
                            active={activeKey === 'quarterly'}
                            onClick={() => setActiveKey('quarterly')}
                        >
                            <div className="ms-2 me-auto">
                                <div className="text-bold my-2">
                                    <span style={{fontSize: '18px'}}>
                                        3 Months
                                    </span>
                                </div>
                                <div className="text-bold text-dark">
                                    {getBasicPriceAmount(basicPrice, 3)}
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="d-flex justify-content-between"
                            active={activeKey === 'semiannually'}
                            onClick={() => setActiveKey('semiannually')}
                        >
                            <div className="ms-2 me-auto">
                                <div className="text-bold my-2">
                                    <span style={{fontSize: '18px'}}>
                                        6 Months
                                    </span>
                                </div>
                                <div className="text-bold text-dark">
                                    {getBasicPriceAmount(basicPrice, 6)}
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            action
                            className="d-flex justify-content-between align-items-start"
                            active={activeKey === 'annually'}
                            onClick={() => setActiveKey('annually')}
                        >
                            <div className="ms-2 me-auto">
                                <div className="text-bold my-2">
                                    <span style={{fontSize: '18px'}}>
                                        10 Months + 2 Months Bonus
                                    </span>
                                </div>
                                <div className="text-bold text-dark">
                                    {getBasicPriceAmount(basicPrice, 10)}
                                </div>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={activeKey === ''}
                        onClick={HandlePaymentMethod}
                    >
                        Select Payment Method
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const PaymentMethodSelectionModal = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                scrollable
                keyboard={false}
                onHide={() => {
                    setPaymentMethodModalShow(false);
                    setActivePaymentMethod('');
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Payment Method
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {ArrayPaymentConfig &&
                            ArrayPaymentConfig.map((item, index) =>
                                PaymentMethodSelectionCard(item, item.typeName)
                            )}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={activePaymentMethod === ''}
                        onClick={HandlePayment}
                    >
                        Continue Payment
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const PaymentMethodSelectionCard = (item, headerTitle) => {
        return (
            <div className="pb-2">
                <div>{headerTitle}</div>
                {paymentMethodSelection.map(
                    (method, index) =>
                        item.codeList.includes(method.paymentMethod) && (
                            <ListGroup.Item
                                action
                                className="d-flex justify-content-between align-items-start"
                                active={
                                    activePaymentMethod === method.paymentMethod
                                }
                                onClick={() =>
                                    setActivePaymentMethod(method.paymentMethod)
                                }
                            >
                                <div className="ms-2 me-auto">
                                    <div>
                                        <span
                                            className="pr-2"
                                            style={{
                                                fontSize: '12px'
                                            }}
                                        >
                                            {method.paymentName}
                                        </span>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )
                )}
            </div>
        );
    };

    const UnpaidPaymentCard = (data) => {
        return (
            unpaidBilling !== null && (
                <div className="info-box">
                    <span className="info-box-icon bg-danger">
                        <i className="far fa-credit-card" />
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">
                            <h5 className="text-bold">
                                You Have Unpaid Billing!
                            </h5>
                        </span>
                        <span>
                            <div>
                                Please make a subscription package payment of
                                immediately
                            </div>
                        </span>
                        <span>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                    setPleaseWaitShow(true);
                                    window.location.replace(
                                        unpaidBilling.invoiceUrl
                                    );
                                }}
                            >
                                Pay Now
                            </button>
                        </span>
                    </div>
                </div>
            )
        );
    };

    const fetchAPIUnpaidBilling = async () => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getBillingUnpaid();

            if (response && response.billingUnpaid) {
                setUnpaidBilling(response.billingUnpaid);
                setBilling(response.billing);
            } else {
                setDisableSubscription(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const fetchAPIPaymentMethod = async () => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getPaymentMethod();
            if (response && response.paymentFee) {
                setPaymentMethodSelection(response.paymentFee);
            } else {
                // Issued when get payment method
                setPaymentMethodSelection(null);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    /*
     * Initial
     */
    useEffect(async () => {
        await Promise.all([fetchAPIUnpaidBilling(), fetchAPIPaymentMethod()]);
    }, []);

    return (
        <>
            <TermSelectionModal show={termModalShow} />
            <PaymentMethodSelectionModal show={paymentMethodModalShow} />
            <ModalSpinnerPleaseWait show={pleaseWaitShow} />
            <UnpaidPaymentCard data={billing} />
            <Card>
                <Card.Header as="h4">Package Selection</Card.Header>
                <Card.Body>
                    <Container className="my-3">
                        <Row>
                            <Col className="text-center">
                                <h3 className="font-weight-bold">
                                    Increase productivity and improve
                                    collaboration with ALCASign
                                </h3>
                                <span>
                                    Select a subscription plan for your team or
                                    try advanced functionality for free.
                                </span>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>

                <Row>
                    {packageStore.plan === 'TRIAL' && (
                        <Col>
                            <Card.Body className="d-flex justify-content-center h-100">
                                <Card
                                    style={{
                                        maxWidth: '29rem',
                                        minWidth: '25rem'
                                    }}
                                >
                                    <Card.Body className="text-center">
                                        <div className="bg-gradient-secondary">
                                            <strong>FREE TRIAL</strong>
                                        </div>
                                        <div style={{color: 'gray'}}>
                                            Try the Starter Plan <br />
                                            free for 7 days
                                        </div>
                                    </Card.Body>
                                    <ListGroup className="list-group-flush">
                                        <ListGroup.Item>
                                            <div style={{display: 'block'}}>
                                                <i
                                                    className="fa fa-arrow-circle-right"
                                                    style={{padding: '6px'}}
                                                />
                                                <span
                                                    style={{fontSize: '18px'}}
                                                >
                                                    Send documents for signature
                                                </span>
                                                <div
                                                    style={{
                                                        marginLeft: '28px',
                                                        color: 'gray'
                                                    }}
                                                >
                                                    Send documents to one or
                                                    multiple recipients to fill
                                                    out and sign
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div style={{display: 'block'}}>
                                                <i
                                                    className="fa fa-arrow-circle-right"
                                                    style={{padding: '6px'}}
                                                />
                                                <span
                                                    style={{fontSize: '18px'}}
                                                >
                                                    Fillable fields
                                                </span>
                                                <div
                                                    style={{
                                                        marginLeft: '28px',
                                                        color: 'gray'
                                                    }}
                                                >
                                                    Let your senders create
                                                    invites by adding different
                                                    fillable fields such as
                                                    Signature, Image
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div style={{display: 'block'}}>
                                                <i
                                                    className="fa fa-arrow-circle-right"
                                                    style={{padding: '6px'}}
                                                />
                                                <span
                                                    style={{fontSize: '18px'}}
                                                >
                                                    Limited templates flow
                                                </span>
                                                <div
                                                    style={{
                                                        marginLeft: '28px',
                                                        color: 'gray'
                                                    }}
                                                >
                                                    Generate templates and
                                                    create new copies of your
                                                    most used documents
                                                    <br />
                                                    (Up to 1 template)
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div style={{display: 'block'}}>
                                                <i
                                                    className="fa fa-arrow-circle-right"
                                                    style={{padding: '6px'}}
                                                />
                                                <span
                                                    style={{fontSize: '18px'}}
                                                >
                                                    Limited document to sign
                                                </span>
                                                <div
                                                    style={{
                                                        marginLeft: '28px',
                                                        color: 'gray'
                                                    }}
                                                >
                                                    Document for sign with
                                                    limited of usage
                                                    <br />
                                                    (Up to 3 Documents)
                                                </div>
                                            </div>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body className="text-center">
                                        <Button
                                            variant="outline-secondary"
                                            disabled
                                        >
                                            Valid until{' '}
                                            {dateConvertPattern(
                                                subscriptionStore.endDate
                                            )}
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Card.Body>
                        </Col>
                    )}

                    <Col>
                        <Card.Body className="d-flex justify-content-center h-100">
                            <Card
                                style={{maxWidth: '29rem', minWidth: '25rem'}}
                            >
                                <Card.Body className="text-center">
                                    <div className="bg-gradient-info">
                                        <strong>STARTER</strong>
                                    </div>
                                    <div style={{color: 'gray'}}>
                                        Standard packages <br />
                                        for your business at affordable prices
                                    </div>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Send documents for signature
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Send documents to one or
                                                multiple recipients to fill out
                                                and sign
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Fillable fields
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Let your senders create invites
                                                by adding different fillable
                                                fields such as Signature, Image
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Basic templates flow limit
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Generate templates and create
                                                new copies of your most used
                                                documents
                                                <br />
                                                (Up to 10 templates)
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Basic document to sign
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Document for sign with limited
                                                of usage
                                                <br />
                                                (Up to 50 Documents)
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <Card.Body className="text-center">
                                    <Button
                                        hidden={disableSubscription}
                                        variant="primary font-weight-bold"
                                        onClick={() =>
                                            handleSelectPackage('STARTER')
                                        }
                                    >
                                        Choose STARTER Plan
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Col>
                    <Col>
                        <Card.Body className="d-flex justify-content-center h-100">
                            <Card
                                style={{maxWidth: '29rem', minWidth: '25rem'}}
                            >
                                <Card.Body className="text-center">
                                    <Card.Text>
                                        <div className="bg-gradient-success">
                                            <strong>PRO</strong>
                                        </div>
                                        <div style={{color: 'gray'}}>
                                            Premium package <br /> with more
                                            comprehensive features
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Send documents for signature
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Send documents to one or
                                                multiple recipients to fill out
                                                and sign
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Fillable fields
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Let your senders create invites
                                                by adding different fillable
                                                fields such as Signature, Image
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Medium templates flow limit
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Generate templates and create
                                                new copies of your most used
                                                documents
                                                <br />
                                                (Up to 50 templates)
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Medium document to sign
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Document for sign with limited
                                                of usage
                                                <br />
                                                (Up to 100 Documents)
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <Card.Body className="text-center">
                                    <Button
                                        hidden={disableSubscription}
                                        variant="outline-primary font-weight-bold"
                                        onClick={() =>
                                            handleSelectPackage('PRO')
                                        }
                                    >
                                        Choose PRO Plan
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Col>
                    <Col>
                        <Card.Body className="d-flex justify-content-center h-100">
                            <Card
                                style={{maxWidth: '29rem', minWidth: '25rem'}}
                            >
                                <Card.Body className="text-center">
                                    <div className="bg-gradient-danger">
                                        <strong>ENTERPRISE</strong>
                                    </div>
                                    <div style={{color: 'gray'}}>
                                        Premium package <br /> with customized
                                        features
                                    </div>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Send documents for signature
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Send documents to one or
                                                multiple recipients to fill out
                                                and sign
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Fillable fields
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Let your senders create invites
                                                by adding different fillable
                                                fields such as Signature, Image
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Custom templates flow limit
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Generate templates and create
                                                new copies of your most used
                                                documents with{' '}
                                                <strong>custom</strong> of usage
                                                limit
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div style={{display: 'block'}}>
                                            <i
                                                className="fa fa-arrow-circle-right"
                                                style={{padding: '6px'}}
                                            />
                                            <span style={{fontSize: '18px'}}>
                                                Custom document to sign
                                            </span>
                                            <div
                                                style={{
                                                    marginLeft: '28px',
                                                    color: 'gray'
                                                }}
                                            >
                                                Document for sign with{' '}
                                                <strong>custom</strong> of usage
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <Card.Body className="text-center">
                                    <Button
                                        hidden={disableSubscription}
                                        variant="outline-primary font-weight-bold"
                                    >
                                        Contact Our Sales
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default SubscriptionPlan;

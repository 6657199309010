import React, {useCallback, useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadUser, logoutUser} from '@store/reducers/auth';
import {loadSubscription} from '@store/reducers/subscription';
import {toggleSidebarMenu} from '@app/store/reducers/ui';
import DocuGate from '@app/services/network/docugate';
import Profiles from '@pages/profile/Profiles';
import Subscription from '@pages/profile/Subscription';
import TemplateFlow from '@pages/template-flows/TemplateFlow';
import Packages from '@app/pages/package/Package';
import Invoice from '@app/pages/billing/Invoice';
import Upload from '@app/pages/transaction/TestPage';
import Documents from '@pages/document/Documents';
import DocumentsUploadForm from '@pages/document/DocumentsUploadForm';
import Archives from '@pages/document/Archives';
import Trash from '@pages/document/Trash';
import TemplateFlowHeaderForm from '@pages/template-flows/TemplateFlowHeaderForm';
import TemplateFlowDetailForm from '@pages/template-flows/TemplateFlowDetailForm';
import TemplateFlowDetail from '@pages/template-flows/TemplateFlowDetail';
import RouteUrl from '@app/routes/RouteUrl';
import Blank from '@pages/Blank';
import SubscriptionPlan from '@pages/subscription/SubscriptionPlan';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import PageLoading from '../../components/page-loading/PageLoading';
import Header from './header/Header';
import Footer from './footer/Footer';

const Main = () => {
    const dispatch = useDispatch();
    const isSidebarMenuCollapsed = useSelector(
        (state) => state.ui.isSidebarMenuCollapsed
    );
    const screenSize = useSelector((state) => state.ui.screenSize);
    const [isAppLoaded, setIsAppLoaded] = useState(false);

    const handleToggleMenuSidebar = () => {
        dispatch(toggleSidebarMenu());
    };

    const fetchProfile = async () => {
        try {
            const response = await DocuGate.getProfile();
            dispatch(loadUser(response));
            dispatch(loadSubscription(response));
            setIsAppLoaded(true);
        } catch (error) {
            dispatch(logoutUser());
            setIsAppLoaded(true);
        }
    };

    useEffect(() => {
        document.getElementById('root').classList.remove('register-page');
        document.getElementById('root').classList.remove('login-page');
        document.getElementById('root').classList.remove('hold-transition');

        document.getElementById('root').classList.add('sidebar-mini');
        document.getElementById('root').classList.add('layout-fixed');

        fetchProfile();
        return () => {
            document.getElementById('root').classList.remove('sidebar-mini');
            document.getElementById('root').classList.remove('layout-fixed');
        };
    }, []);

    useEffect(() => {
        document.getElementById('root').classList.remove('sidebar-closed');
        document.getElementById('root').classList.remove('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
        if (isSidebarMenuCollapsed && screenSize === 'lg') {
            document.getElementById('root').classList.add('sidebar-collapse');
        } else if (isSidebarMenuCollapsed && screenSize === 'xs') {
            document.getElementById('root').classList.add('sidebar-open');
        } else if (!isSidebarMenuCollapsed && screenSize !== 'lg') {
            document.getElementById('root').classList.add('sidebar-closed');
            document.getElementById('root').classList.add('sidebar-collapse');
        }
    }, [screenSize, isSidebarMenuCollapsed]);

    const getAppTemplate = useCallback(() => {
        if (!isAppLoaded) {
            return <PageLoading />;
        }
        return (
            <>
                <Header toggleMenuSidebar={handleToggleMenuSidebar} />

                <MenuSidebar />

                <div className="content-wrapper">
                    <div className="pt-3" />
                    <section className="content">
                        <Switch>
                            <Route
                                exact
                                path={RouteUrl.Subscription}
                                component={Subscription}
                            />
                            <Route
                                exact
                                path={RouteUrl.SubscriptionPlan}
                                component={SubscriptionPlan}
                            />
                            <Route
                                exact
                                path={RouteUrl.Package}
                                component={Packages}
                            />
                            <Route
                                exact
                                path={RouteUrl.Invoice}
                                component={Invoice}
                            />
                            <Route
                                exact
                                path={RouteUrl.Profile}
                                component={Profiles}
                            />
                            <Route
                                exact
                                path={RouteUrl.Documents}
                                component={Documents}
                            />
                            <Route
                                exact
                                path={RouteUrl.DocumentsUpload}
                                component={DocumentsUploadForm}
                            />
                            <Route
                                exact
                                path={RouteUrl.Trash}
                                component={Trash}
                            />
                            <Route
                                exact
                                path={RouteUrl.Archives}
                                component={Archives}
                            />
                            <Route
                                exact
                                path={RouteUrl.TemplateFlow}
                                component={TemplateFlow}
                            />
                            <Route
                                exact
                                path={RouteUrl.TemplateFlowHeaderForm}
                                component={TemplateFlowHeaderForm}
                            />
                            <Route
                                exact
                                path={RouteUrl.TemplateFlowDetailFrom}
                                component={TemplateFlowDetailForm}
                            />
                            <Route
                                exact
                                path={RouteUrl.TemplateFlowDetail}
                                component={TemplateFlowDetail}
                            />
                            <Route exact path="/blank" component={Blank} />
                            <Route exact path="/upload" component={Upload} />
                            <Route exact path="/" component={Documents} />
                        </Switch>
                    </section>
                </div>
                <Footer />
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={handleToggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </>
        );
    }, [isAppLoaded]);

    return <div className="wrapper">{getAppTemplate()}</div>;
};

export default Main;

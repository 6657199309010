import React, {useEffect, useState} from 'react';
import {Button, ContentDetail, ContentHeader} from '@components';
import DocuGate from '@app/services/network/docugate';
import TemplateFlowDetailTable from '@pages/template-flows/TemplateFlowDetailTable';
import {useHistory} from 'react-router-dom';
import RouteUrl from '@app/routes/RouteUrl';
import {useRouter} from '@app/hooks/use-router';
import TemplateFlowDetailTableHeader from '@pages/template-flows/TemplateFlowDetailTableHeader';

const TemplateFlowDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [recordsData, setRecordsData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const router = useRouter();
    const history = useHistory();

    const getDetailList = async (headerId) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getFlowDetailByHeaderId(headerId);
            if (response) {
                setRecordsData(response.details);
            }
        } catch (error) {
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    const getHeader = async (headerId) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getFlowHeaderByHeaderId(headerId);
            if (response) {
                const arr = [];
                arr.push(response.flow);
                setHeaderData(arr);
            }
        } catch (error) {
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    const editApprover = (headerId, detailId) => {
        history.push(
            RouteUrl.TemplateFlowDetailFrom.replace(
                ':headerId',
                headerId
            ).replace(':detailId', detailId)
        );
    };

    /*
     * Initial
     */
    useEffect(() => {
        const {headerId} = router.query;
        getDetailList(headerId);
        getHeader(headerId);
    }, []);

    return (
        <div>
            <ContentDetail
                title="Flow Detail"
                prevTitle="Template Flow"
                prevUrl={RouteUrl.TemplateFlow}
            />
            <section className="content">
                <div className="container">
                    <div className="card">
                        <div className="card-body p-0">
                            <TemplateFlowDetailTableHeader
                                recordsData={headerData}
                                isLoadingData={isLoading}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body p-0">
                            <TemplateFlowDetailTable
                                recordsData={recordsData}
                                isLoadingData={isLoading}
                                editApprover={editApprover}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <Button
                            icon="close"
                            theme="primary"
                            className="ml-1 mr-1"
                            onClick={() => history.push(RouteUrl.TemplateFlow)}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TemplateFlowDetail;

import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React, {useState} from 'react';
import {Button, ModalConfirm} from '@components';
import {useHistory} from 'react-router-dom';

const DocumentsTable = ({
    recordsData,
    isLoadingData,
    goPageAnnotate,
    funcInviteDocument
}) => {
    const history = useHistory();
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [documentToInvite, setDocumentToInvite] = useState(null);

    return (
        <div>
            <div className="table-responsive mailbox-messages">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th>Document Name</th>
                            <th className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={3}>
                                    <b>Loading Data...</b>
                                </td>
                            </tr>
                        )}
                        {recordsData.length > 0 &&
                            !isLoadingData &&
                            recordsData.map((row, index) => {
                                if (
                                    row.status === 'Draft' ||
                                    row.status === 'Sent'
                                ) {
                                    return (
                                        <tr key={`tr-rows-${uuidv4()}`}>
                                            {/* <td> */}
                                            {/*    <div className="icheck-primary small"> */}
                                            {/*        <input */}
                                            {/*            key={`checkbox-${uuidv4()}`} */}
                                            {/*            type="checkbox" */}
                                            {/*            value="" */}
                                            {/*            id={`checkbox-${index}`} */}
                                            {/*        /> */}
                                            {/*        <label */}
                                            {/*            htmlFor={`checkbox-${index}`} */}
                                            {/*        > */}
                                            {/*            <span /> */}
                                            {/*        </label> */}
                                            {/*    </div> */}
                                            {/* </td> */}
                                            <td>{row.DocName}</td>
                                            <td>
                                                {row.status === 'Draft' && (
                                                    <div className="float-right">
                                                        <Button
                                                            icon="send"
                                                            theme="outline-primary"
                                                            className="mr-1"
                                                            onClick={() => {
                                                                setDocumentToInvite(
                                                                    row.Id
                                                                );
                                                                setIsShowConfirm(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Invite Sign
                                                        </Button>
                                                    </div>
                                                )}
                                                {row.status === 'Draft' && (
                                                    <div className="float-right">
                                                        <Button
                                                            icon="signing"
                                                            theme="outline-primary"
                                                            className="mr-1"
                                                            onClick={() => {
                                                                goPageAnnotate(
                                                                    row.Id
                                                                );
                                                            }}
                                                        >
                                                            Prepare
                                                        </Button>
                                                    </div>
                                                )}
                                                {row.status === 'Sent' && (
                                                    <div className="float-right">
                                                        <Button
                                                            icon="waiting"
                                                            theme="outline-info"
                                                            className="mr-1"
                                                            onClick={() => {}}
                                                        >
                                                            Signing In Progress
                                                        </Button>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                }
                                return '';
                            })}
                        {recordsData.length <= 0 && !isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={6}>
                                    <b>No Data</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <ModalConfirm
                show={isShowConfirm}
                title="Invite Sign Confirmation"
                content="Do you want to send this document to sign?"
                handleClose={() => setIsShowConfirm(false)}
                handleSubmit={() => {
                    setIsShowConfirm(false);
                    funcInviteDocument(documentToInvite);
                }}
            />
        </div>
    );
};

DocumentsTable.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    goPageAnnotate: PropTypes.func.isRequired,
    funcInviteDocument: PropTypes.func.isRequired
};

export default DocumentsTable;

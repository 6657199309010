import React, {useState} from 'react';
import {ContentHeader, Button} from '@components';
import DocuGate from '@app/services/network/docugate';

const TestPage = () => {
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    // const [isFilePicked, setIsFilePicked] = useState(false);

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);

        setIsSelected(true);
        // console.log(event.target.previousElementSibling.value);
    };

    const handleSubmission = () => {
        const formData = new FormData();

        formData.append('file', selectedFile);

        fetch(
            'http://localhost:3000/v1/fileupload/fileupload',

            {
                method: 'POST',
                headers: {
                    Authorization:
                        'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2ZTU1YWE4MS1jODQ4LTQ5MjAtOWQ5MS01OTllOGJiOTVjOTQiLCJpYXQiOjE2NTQwOTk1NzgsImV4cCI6MTY1NjY5MTU3OCwidHlwZSI6ImFjY2VzcyJ9.Iilqm0X6GMzXCToTruBySXDOkuK-rEKaAeiVGgiois4'
                },
                body: formData
            }
        )
            .then((response) => response.json())

            .then((result) => {
                console.log('Success:', result);
            })

            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const handlesubmit = async (value) => {
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append(
                'flowHeaderId',
                document.getElementById('flowHeaderId').value
            );
            // console.log(document.getElementById('flowHeaderId').value);
            const response = await DocuGate.upload(formData);
            if (response) {
                console.log('Success:', response);
            }
        } catch (error) {
            // dispatch(logoutUser());
        }
    };

    return (
        <div>
            <ContentHeader title="TestPage" />
            <section className="content">
                <div className="container-fluid">
                    <input
                        type="text"
                        id="flowHeaderId"
                        name="flowHeaderId"
                        value="67c278b0-c9e3-4c81-85dd-3a552de74867"
                    />
                    <input type="file" name="file" onChange={changeHandler} />
                    {isSelected ? (
                        <div>
                            <p>Filename: {selectedFile.name}</p>

                            <p>Filetype: {selectedFile.type}</p>

                            <p>Size in bytes: {selectedFile.size}</p>

                            {/* <p>
                                lastModifiedDate:{' '}
                                {selectedFile.lastModifiedDate.toLocaleDateString()}
                            </p> */}
                        </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )}

                    <div>
                        <Button onClick={handlesubmit}>Submit</Button>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TestPage;

import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {ButtonIcon} from '@components';
import {useHistory} from 'react-router-dom';
import RouteUrl from '@app/routes/RouteUrl';

const TemplateFlowDetailTableHeader = ({recordsData, isLoadingData}) => {
    return (
        <div>
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th className="text-left">Flow Name</th>
                        <th className="text-left">Description</th>
                        <th className="text-left">Approval Level</th>
                        <th className="text-center">Number of Approver</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={4}>
                                <b>Loading Data...</b>
                            </td>
                        </tr>
                    )}
                    {recordsData.length > 0 &&
                        !isLoadingData &&
                        recordsData.map((row, index) => {
                            return (
                                <tr key={`tr-rows-${uuidv4()}`}>
                                    <td>{row.flowName}</td>
                                    <td>{row.flowDesc}</td>
                                    <td>{row.approvalLevel}</td>
                                    <td className="text-center">
                                        {row.approverCount}
                                    </td>
                                </tr>
                            );
                        })}
                    {recordsData.length <= 0 && !isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={4}>
                                <b>No Data</b>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

TemplateFlowDetailTableHeader.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired
};

export default TemplateFlowDetailTableHeader;

import React, {useEffect, useState} from 'react';
import {Button, Input, ContentDetail} from '@components';
import DocuGate from '@app/services/network/docugate';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import RouteUrl from '@app/routes/RouteUrl';
import {useHistory} from 'react-router-dom';
import {useRouter} from '@app/hooks/use-router';

const TemplateFlowDetailForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const history = useHistory();
    const router = useRouter();
    const {headerId, detailId} = router.query;

    const formik = useFormik({
        initialValues: {
            approverName: '',
            approvalLevel: '',
            approverMail: ''
        },
        validationSchema: Yup.object({
            approverName: Yup.string().required('Required'),
            approvalLevel: Yup.number()
                .min(1, 'Must be 1 or more')
                .required('Required'),
            approverMail: Yup.string()
                .email('Email address not valid')
                .required('Required')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        },
        enableReinitialize: true
    });

    const getReturnPageDetailUrl = () => {
        const {flowHeaderId} = data;
        return RouteUrl.TemplateFlowDetail.replace(':headerId', flowHeaderId);
    };
    const returnPageDetail = () => {
        const {flowHeaderId} = data;
        history.push(
            RouteUrl.TemplateFlowDetail.replace(':headerId', flowHeaderId)
        );
    };

    const getApproverDetail = async (headerId, detailId) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getFlowDetailByDetailId(
                headerId,
                detailId
            );
            if (response) {
                const {detail} = response;
                setData(detail);
                await formik.setValues({
                    approverName: detail.approverName || '',
                    approvalLevel: detail.approvalLevel || '',
                    approverMail: detail.approverMail || ''
                });
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (value) => {
        try {
            const {flowHeaderId, flowDetailId} = data;
            setIsLoading(true);
            const response = await DocuGate.updateFlowDetailByDetailId(
                flowHeaderId,
                flowDetailId,
                value
            );
            if (response) {
                toast.success('Approver Detail Updated');
                returnPageDetail();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = async () => {
        returnPageDetail();
    };

    /*
     * Initial
     */
    useEffect(() => {
        getApproverDetail(headerId, detailId);
    }, []);

    return (
        <div>
            <ContentDetail
                isShowTitle={false}
                title="Approval Detail"
                prevTitle="Template Flow Detail"
                prevUrl={getReturnPageDetailUrl()}
            />
            <section className="content">
                <div className="container-fluid col-6">
                    <div className="card">
                        <div className="card-header">
                            <h5>Please Define Approver Detail</h5>
                        </div>
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="approverName">
                                                    Approver Name
                                                </label>
                                                <Input
                                                    id="approverName"
                                                    name="approverName"
                                                    type="text"
                                                    placeholder="Name of the approver"
                                                    formik={formik}
                                                    value={
                                                        formik.values
                                                            .approverName
                                                    }
                                                    formikFieldProps={formik.getFieldProps(
                                                        'approverName'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="approvalLevel">
                                                    Approval Level
                                                </label>
                                                <Input
                                                    id="approvalLevel"
                                                    name="approvalLevel"
                                                    type="text"
                                                    placeholder="Level of the approver"
                                                    formik={formik}
                                                    value={
                                                        formik.values
                                                            .approvalLevel
                                                    }
                                                    formikFieldProps={formik.getFieldProps(
                                                        'approvalLevel'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group">
                                                <label htmlFor="approverMail">
                                                    Email Address
                                                </label>
                                                <Input
                                                    id="approverMail"
                                                    name="approverMail"
                                                    type="text"
                                                    placeholder="Email address of the approver"
                                                    formik={formik}
                                                    value={
                                                        formik.values
                                                            .approverMail
                                                    }
                                                    formikFieldProps={formik.getFieldProps(
                                                        'approverMail'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <Button
                                                    theme="secondary"
                                                    className="mt-4"
                                                    block
                                                    onClick={handleCancel}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-6">
                                                <Button
                                                    isLoading={isLoading}
                                                    className="mt-4"
                                                    type="submit"
                                                    block
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TemplateFlowDetailForm;

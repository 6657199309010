import React, {useEffect, useState} from 'react';
import DocuGate from '@app/services/network/docugate';
import {useHistory} from 'react-router-dom';
import BillingTabTable from '@pages/profile/BillingTabTable';
import {ButtonIcon, ModalSpinner, PaginationComponent} from '@components';
import {toast} from 'react-toastify';

const BillingTab = ({isActive}) => {
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [pleaseWaitShow, setPleaseWaitShow] = useState(false);

    const history = useHistory();

    const goToPage = (billingId, status) => {
        history.push(
            `/invoice?term=detail&code=${status}&meta=${billingId}&masking=af12-22349e8b62c4`
        );
    };

    const fetchBillings = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getProfileBilling(
                Number(pageNumber),
                recordsPerPage,
                'billingDate:desc'
            );

            if (response) {
                if (response.results) {
                    setRecordsData(response.results);
                    setRecordsTotal(response.totalResults);
                }
            }

            setIsLoading(false);
        } catch (error) {
            // dispatch(logoutUser());
        }
    };

    const goToPaymentPage = async (billingId) => {
        try {
            const response = await DocuGate.getBillingPaymentLink(billingId);

            if (response) {
                const {invoiceUrl} = response;
                setPleaseWaitShow(true);
                window.location.replace(invoiceUrl);
            }
        } catch (e) {
            toast.error('Payment Link Not Found');
        }
    };

    const handleRefresh = async () => {
        await fetchBillings(1);
    };

    /*
     * Initial
     */
    useEffect(async () => {
        await fetchBillings(1);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {}, [recordsData]);
    useEffect(() => {}, [isActive]);
    useEffect(() => {}, [recordsTotal]);
    useEffect(async () => {
        await fetchBillings(currentPage);
    }, [currentPage]);

    const ModalSpinnerPleaseWait = () => {
        return (
            <ModalSpinner centered show={pleaseWaitShow} isLoading="true">
                <div className="text-center">
                    <p>You will be redirected to Payment Page</p>
                    <p className="text-bold">Please Wait</p>
                </div>
            </ModalSpinner>
        );
    };

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <ModalSpinnerPleaseWait show={pleaseWaitShow} />
            <div className="card border-primary">
                <div className="card-header">
                    <div className="float-left">
                        <div className="input-group input-group-sm">
                            <ButtonIcon
                                icon="refresh"
                                theme="outline-primary"
                                onClick={handleRefresh}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body p-0">
                    <BillingTabTable
                        recordsData={recordsData}
                        isLoadingData={isLoading}
                        goToPage={goToPage}
                        goToPaymentPage={goToPaymentPage}
                    />
                </div>
                <div className="card-footer clearfix">
                    <PaginationComponent
                        recordsTotal={recordsTotal}
                        recordsPerPage={recordsPerPage}
                        isLoadingData={isLoading}
                        onPageChanges={setCurrentPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default BillingTab;

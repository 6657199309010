import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ContentHeader} from '@components';
import BillingTab from './BillingTab';
import AccountTab from './AccountTab';
import SubscriptionTab from './SubscriptionTab';

const Profiles = () => {
    const [activeTab, setActiveTab] = useState('PROFILE');
    const [t] = useTranslation();

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <>
            <ContentHeader title="My Account" />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary card-outline card-tabs">
                                <div className="card-header p-0 pt-1 border-bottom-0">
                                    <ul
                                        className="nav nav-tabs"
                                        id="custom-tabs-three-tab"
                                        role="tablist"
                                    >
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${
                                                    activeTab === 'PROFILE'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                id="custom-tabs-three-home-tab"
                                                data-toggle="pill"
                                                href="#user-profile"
                                                role="tab"
                                                aria-controls="custom-tabs-three-home"
                                                aria-selected="true"
                                                onClick={() =>
                                                    toggle('PROFILE')
                                                }
                                            >
                                                {t('profile.label.userProfile')}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${
                                                    activeTab === 'SUBSCRIPTION'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                id="custom-tabs-three-profile-tab"
                                                data-toggle="pill"
                                                href="#subscription"
                                                role="tab"
                                                aria-controls="custom-tabs-three-profile"
                                                aria-selected="false"
                                                onClick={() =>
                                                    toggle('SUBSCRIPTION')
                                                }
                                            >
                                                {t(
                                                    'profile.label.subscription'
                                                )}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${
                                                    activeTab === 'BILLING'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                id="custom-tabs-three-messages-tab"
                                                data-toggle="pill"
                                                href="#billing"
                                                role="tab"
                                                aria-controls="custom-tabs-three-messages"
                                                aria-selected="false"
                                                onClick={() =>
                                                    toggle('BILLING')
                                                }
                                            >
                                                {t('profile.label.billing')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <AccountTab
                                            isActive={activeTab === 'PROFILE'}
                                        />
                                        <SubscriptionTab
                                            isActive={
                                                activeTab === 'SUBSCRIPTION'
                                            }
                                        />
                                        <BillingTab
                                            isActive={activeTab === 'BILLING'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Profiles;

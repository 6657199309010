import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {ButtonIcon} from '@components';

const TemplateFlowDetailTable = ({
    recordsData,
    isLoadingData,
    editApprover
}) => {
    return (
        <div>
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th className="text-left">Name</th>
                        <th className="text-left">Level</th>
                        <th className="text-left">Email</th>
                        <th className="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={4}>
                                <b>Loading Data...</b>
                            </td>
                        </tr>
                    )}
                    {recordsData.length > 0 &&
                        !isLoadingData &&
                        recordsData.map((row, index) => {
                            return (
                                <tr key={`tr-rows-${uuidv4()}`}>
                                    <td>
                                        {row.approverName || (
                                            <i className="text-gray">
                                                Name Not Set
                                            </i>
                                        )}
                                    </td>
                                    <td>
                                        {row.approvalLevel || (
                                            <i className="text-gray">
                                                Level Not Set
                                            </i>
                                        )}
                                    </td>
                                    <td>
                                        {row.approverMail || (
                                            <i className="text-gray">
                                                Email Not Set
                                            </i>
                                        )}
                                    </td>
                                    <td>
                                        <div className="float-right">
                                            <ButtonIcon
                                                icon="edit"
                                                theme="outline-primary"
                                                className="mr-1"
                                                onClick={() => {
                                                    editApprover(
                                                        row.flowHeaderId,
                                                        row.flowDetailId
                                                    );
                                                }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    {recordsData.length <= 0 && !isLoadingData && (
                        <tr key={`tr-rows-${uuidv4()}`} className="text-center">
                            <td colSpan={6}>
                                <b>No Data</b>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

TemplateFlowDetailTable.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    editApprover: PropTypes.func.isRequired
};

export default TemplateFlowDetailTable;

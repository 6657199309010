import React, {useState, useEffect} from 'react';
import DocuGate from '@app/services/network/docugate';
import {toast} from 'react-toastify';
import {useHistory} from 'react-router-dom';
import {Button} from '@components';

const Packages = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [getPackages, setPackages] = useState([]);
    const [getTerm, setTerm] = useState(1);
    const history = useHistory();

    const submitSubscribe = async (_packageId) => {
        try {
            setIsLoading(true);
            const request = {
                packageId: _packageId,
                term: getTerm
            };
            await DocuGate.postSubscribePackages(request);
            history.push('/');
        } catch (error) {
            toast.error(error);
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAvailablePackages = async () => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getAvailablePackages();
            if (response.packages) {
                setPackages(response.packages);
            }
        } catch (error) {
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAvailablePackages();
    }, []);

    return (
        <section className="content">
            <div className="row">
                {isLoading && (
                    <div className="overlay">
                        <i className="fas fa-2x fa-sync-alt fa-spin" />
                    </div>
                )}
                {!isLoading &&
                    getPackages.map((item) => (
                        <div className="col-4" key={item.packageId}>
                            <div className="container-fluid">
                                <div className="card card-info">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            {item.planName}
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="list-unstyled">
                                            {item.packagePages.map((el) => (
                                                <div
                                                    className="pt-1 pb-1"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={el.split('::')[1]}
                                                    key={`${item.packageId}-${el}`}
                                                >
                                                    <span className="p-1">
                                                        <i className="fa fa-check fa-1x" />
                                                    </span>
                                                    <span className="p-1">
                                                        {' '}
                                                        {el.split('::')[0]}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="form-group pt-3">
                                            <span>Price Selection : </span>
                                            <select
                                                onChange={(e) =>
                                                    setTerm(e.target.value)
                                                }
                                                className="mt-2 form-control select2"
                                            >
                                                {item.termsPayment
                                                    .split('|')
                                                    .map((el) => (
                                                        <option
                                                            value={`${el}`}
                                                            key={`${item.plan}${el}`}
                                                        >
                                                            {el} Month - Rp.{' '}
                                                            {Number(
                                                                item.basicPrice *
                                                                    el
                                                            ).toLocaleString()}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <Button
                                            isLoading={isLoading}
                                            className="btn-block"
                                            onClick={() =>
                                                submitSubscribe(item.packageId)
                                            }
                                        >
                                            Subscribe Now
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </section>
    );
};

export default Packages;

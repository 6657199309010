import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ModalConfirm = ({
    show,
    title = '',
    content = '',
    handleClose,
    handleSubmit,
    ...otherProps
}) => {
    return (
        <Modal {...otherProps} show={show} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-dark" onClick={handleClose}>
                    <i className="fas fa-window-close mr-2" />
                    Cancel
                </Button>
                <Button variant="outline-primary" onClick={handleSubmit}>
                    <i className="fa fa-check-square mr-2" />
                    Continue
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalConfirm;

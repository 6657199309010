import React, {useEffect} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import RegisterOffer from '@modules/register/RegisterOffer';
import RegisterSuccess from '@modules/register/RegisterSuccess';
import RegisterInvoice from '@modules/register/RegisterInvoice';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import PrivacyPolicy from '@modules/privacy-policy/PrivacyPolicy';
import DocumentSign from '@modules/annotate/template';
import DocumentSignDownload from '@modules/annotate/template/user/generatePdf';
import Gen from '@modules/annotate/template/generate';
import DocumentUserSign from '@modules/annotate/template/user';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import EmptyRoute from './routes/EmptyRoute';

import './App.scss';
import 'semantic-ui-css/semantic.min.css';

const App = () => {
    const windowSize = useWindowSize();
    const screenSize = useSelector((state) => state.ui.screenSize);
    const dispatch = useDispatch();

    useEffect(() => {
        const size = calculateWindowSize(windowSize.width);
        if (screenSize !== size) {
            dispatch(setWindowSize(size));
        }
    }, [windowSize]);

    return (
        <Router>
            <Switch>
                <PublicRoute exact path="/login">
                    <Login />
                </PublicRoute>
                <PublicRoute exact path="/register">
                    <Register />
                </PublicRoute>
                <PublicRoute exact path="/forgot-password">
                    <ForgetPassword />
                </PublicRoute>
                <PublicRoute exact path="/recover-password">
                    <RecoverPassword />
                </PublicRoute>
                <PublicRoute exact path="/privacy-policy">
                    <PrivacyPolicy />
                </PublicRoute>
                <PublicRoute exact path="/callback">
                    <h1>Callback</h1>
                </PublicRoute>
                <PublicRoute exact path="/user-sign">
                    <DocumentUserSign />
                </PublicRoute>
                <PublicRoute exact path="/user-sign-download">
                    <DocumentSignDownload />
                </PublicRoute>

                <PrivateRoute exact path="/annotate">
                    <DocumentSign />
                </PrivateRoute>
                <PrivateRoute exact path="/generate">
                    <Gen />
                </PrivateRoute>
                <PrivateRoute exact path="/register/success">
                    <RegisterSuccess />
                </PrivateRoute>
                <PrivateRoute path="/">
                    <Main />
                </PrivateRoute>
                <EmptyRoute />
            </Switch>
        </Router>
    );
};

export default App;

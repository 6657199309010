import React, {useEffect, useState, useRef} from 'react';
import {
    Button,
    ButtonIcon,
    ContentHeader,
    PaginationComponent
} from '@components';
import DocumentsTable from '@pages/document/DocumentsTable';
import DocuGate from '@app/services/network/docugate';
import {useHistory, withRouter} from 'react-router-dom';
import RouteUrl from '@app/routes/RouteUrl';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {dateConvertPattern} from '@app/helper/formatter';

const Documents = () => {
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const subscriptionStore = useSelector(
        (state) => state.subscription.subscription
    );
    const packageStore = useSelector((state) => state.subscription.package);

    const history = useHistory();

    const fetchDocumentAPI = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getDocuments(
                Number(pageNumber),
                recordsPerPage,
                'createdAt:desc'
            );

            if (response) {
                if (response.results) {
                    setRecordsData(response.results);
                    setRecordsTotal(response.totalResults);
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        fetchDocumentAPI(1);
    };

    const goPageAnnotate = (documentId) => {
        history.push(
            RouteUrl.DocumentSigning.replace(':templateId', documentId)
        );
    };

    const handlerInviteDocument = async (documentId) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.postDocumentSigningInvite(
                documentId
            );

            if (response) {
                if (response.message) {
                    // show message
                    toast.success(response.message);
                    handleRefresh();
                } else {
                    toast.error('Document send failed');
                    handleRefresh();
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const UploadButton = withRouter(({history}) => (
        <Button
            icon="upload"
            className="ml-1 mr-1"
            theme="outline-primary"
            onClick={() => {
                history.push(`${RouteUrl.DocumentsUpload}`);
            }}
        >
            Upload Document
        </Button>
    ));

    /*
     * Initial
     */
    useEffect(() => {
        fetchDocumentAPI(1);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {}, [recordsData]);
    useEffect(() => {}, [recordsTotal]);
    useEffect(() => {
        fetchDocumentAPI(currentPage);
    }, [currentPage]);

    return (
        <div>
            {packageStore.plan === 'TRIAL' && (
                <div className="info-box">
                    <span className="info-box-icon bg-info">
                        <i className="far fa-envelope" />
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">
                            <h5 className="text-bold">
                                You get Free Trial access for 7 days!
                            </h5>
                        </span>
                        <span>
                            <div>
                                Now you can use all features. The free trial
                                period will end on the{' '}
                                <strong>
                                    {dateConvertPattern(
                                        subscriptionStore.endDate
                                    )}
                                </strong>
                            </div>
                        </span>
                    </div>
                </div>
            )}
            <ContentHeader title="Documents" />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="float-left">
                                <div className="input-group input-group-sm">
                                    <ButtonIcon
                                        icon="refresh"
                                        theme="outline-primary"
                                        onClick={handleRefresh}
                                    />
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="input-group input-group-sm">
                                    <div>
                                        <UploadButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <DocumentsTable
                                recordsData={recordsData}
                                isLoadingData={isLoading}
                                goPageAnnotate={goPageAnnotate}
                                funcInviteDocument={handlerInviteDocument}
                            />
                        </div>
                        <div className="card-footer">
                            <PaginationComponent
                                recordsTotal={recordsTotal}
                                recordsPerPage={recordsPerPage}
                                isLoadingData={isLoading}
                                onPageChanges={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Documents;

import DocuGate from '@app/services/network/docugate';

export const loginByAuth = async (email, password) => {
    const token = await DocuGate.loginByAuth(email, password);
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByAuth = async (requestBody) => {
    const token = await DocuGate.registerByAuth(requestBody);
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByGoogle = async (_token, _signInType) => {
    const token = await DocuGate.loginByGoogle(_token, _signInType);
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByGoogle = async () => {
    const token = await DocuGate.registerByGoogle();
    localStorage.setItem('docugate_token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByFacebook = async () => {
    const token = await DocuGate.loginByFacebook();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByFacebook = async () => {
    const token = await DocuGate.registerByFacebook();
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

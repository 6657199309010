// eslint-disable-next-line import/no-unresolved
const axios = require('axios');

const LOCALSTORAGE_IDENTIFIER = 'docugate_token';

const instance = axios.create({
    // baseURL: `http://108.136.73.137:3000`
    // baseURL: `http://localhost:3000`
    baseURL: `${process.env.REACT_APP_GATEKEEPER_URL}`
});

// Add a request interceptor
instance.interceptors.request.use(
    function handleRequest(config) {
        // Do something before request is sent
        const token = localStorage.getItem(LOCALSTORAGE_IDENTIFIER);

        if (token) {
            // console.log('token from api: ', token);
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function handleRequestError(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    function handleResponse(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function handleResponseError(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

module.exports = instance;

import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {Button} from '@components';
import {useHistory} from 'react-router-dom';

const ArchivesTable = ({
    recordsData,
    isLoadingData,
    goPageAnnotate,
    goPageDownload
}) => {
    const history = useHistory();

    return (
        <div>
            <div className="table-responsive mailbox-messages">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th>Document Name</th>
                            <th className="text-right">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={2}>
                                    <b>Loading Data...</b>
                                </td>
                            </tr>
                        )}
                        {recordsData.length > 0 &&
                            !isLoadingData &&
                            recordsData.map((row, index) => {
                                return (
                                    <tr key={`tr-rows-${uuidv4()}`}>
                                        <td>{row.DocName}</td>
                                        <td>
                                            <div className="float-right">
                                                <Button
                                                    icon="download"
                                                    theme="outline-success"
                                                    className="mr-1"
                                                    onClick={() => {
                                                        goPageDownload(row.Id);
                                                    }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        {recordsData.length <= 0 && !isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={3}>
                                    <b>No Data</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

ArchivesTable.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    goPageAnnotate: PropTypes.func.isRequired
};

export default ArchivesTable;

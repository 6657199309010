import React from 'react';

const ContentDetail = ({title, prevUrl, prevTitle, isShowTitle = true}) => {
    return (
        <section className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        {isShowTitle && <h1>{title}</h1>}
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                <a href={prevUrl}>{prevTitle}</a>
                            </li>
                            <li className="breadcrumb-item active">{title}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContentDetail;

import React, {useEffect, useState, useRef} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {
    Button,
    ButtonIcon,
    ContentHeader,
    PaginationComponent
} from '@components';
import DocuGate from '@app/services/network/docugate';
import TemplateFlowTable from '@pages/template-flows/TemplateFlowTable';
import RouteUrl from '@app/routes/RouteUrl';
import {toast} from 'react-toastify';
import ModalConfirm from '../../components/modal/ModalConfirm';

const TemplateFlow = () => {
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);
    const [deleteData, setDeleteData] = useState({});
    const history = useHistory();

    const fetchPaginatedFlowAPI = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getPaginatedFlowHeader(
                currentPage,
                recordsPerPage
            );
            if (response) {
                if (response.results) {
                    setRecordsData(response.results);
                    setRecordsTotal(response.totalResults);
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const fetchDeleteFlowAPI = async (flowId) => {
        try {
            setIsLoading(true);
            // const response = await DocuGate.deleteFlowByFlowHeaderId(flowId);
            const response = await DocuGate.deleteFlowHeaderById(flowId);
            if (response) {
                toast.success('Delete is successfully');
                await handleRefresh();
            } else {
                toast.error('Delete failed');
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const handleRefresh = async () => {
        await fetchPaginatedFlowAPI(1);
    };

    const handleDelete = async () => {
        await fetchDeleteFlowAPI(deleteData.flowId);
        setIsShowConfirmDelete(false);
    };

    const editDetail = (flowId) => {
        history.push(RouteUrl.TemplateFlowDetail.replace(':headerId', flowId));
    };

    const confirmDeleteDetail = async (flowId) => {
        setIsShowConfirmDelete(true);
        setDeleteData({
            flowId
        });
    };

    const CreateFlowButton = withRouter(({history}) => (
        <Button
            icon="create"
            className="ml-1 mr-1"
            theme="outline-primary"
            onClick={() => {
                history.push('/template-flow/header');
            }}
        >
            Create Flow
        </Button>
    ));

    /*
     * Initial
     */
    useEffect(() => {
        fetchPaginatedFlowAPI(1);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {}, [recordsData]);
    useEffect(() => {}, [recordsTotal]);
    useEffect(() => {
        fetchPaginatedFlowAPI(currentPage);
    }, [currentPage]);

    return (
        <div>
            <ContentHeader title="Template Flows" />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="float-left">
                                <div className="input-group input-group-sm">
                                    <ButtonIcon
                                        icon="refresh"
                                        theme="outline-primary"
                                        onClick={handleRefresh}
                                    />
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="input-group input-group-sm">
                                    <div>
                                        <CreateFlowButton />
                                        <Button
                                            icon="deleteAll"
                                            theme="outline-danger"
                                            className="ml-1 mr-1"
                                            hidden
                                        >
                                            Delete Selected
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <TemplateFlowTable
                                recordsData={recordsData}
                                isLoadingData={isLoading}
                                editDetail={editDetail}
                                // deleteDetail={confirmDeleteDetail}
                                deleteDetail={confirmDeleteDetail}
                            />
                        </div>
                        <div className="card-footer">
                            <PaginationComponent
                                recordsTotal={recordsTotal}
                                recordsPerPage={recordsPerPage}
                                isLoadingData={isLoading}
                                onPageChanges={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <ModalConfirm
                show={isShowConfirmDelete}
                title="Delete Confirmation"
                content={<p>Do you want back to delete template flow?</p>}
                handleClose={() => setIsShowConfirmDelete(false)}
                handleSubmit={handleDelete}
            />
        </div>
    );
};

export default TemplateFlow;

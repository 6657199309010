import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import DocuGate from '@app/services/network/docugate';
import {DateTime} from 'luxon';
import {ContentDetail, ModalSpinner} from '@components';

const Invoice = () => {
    const [getBilling, setBilling] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pleaseWaitShow, setPleaseWaitShow] = useState(false);
    const params = new URLSearchParams(useLocation().search);

    const fetchBillingDetail = async (billingId) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getBillingById(billingId);
            if (response.billing && response.billingDetail) {
                setBilling(response);
            }
        } catch (error) {
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBillingDetail(params.get('meta'));
    }, []);

    const ModalSpinnerPleaseWait = () => {
        return (
            <ModalSpinner centered show={pleaseWaitShow} isLoading="true">
                <div className="text-center">
                    <p>You will be redirected to Payment Page</p>
                    <p className="text-bold">Please Wait</p>
                </div>
            </ModalSpinner>
        );
    };

    return (
        <div>
            {String(params.get('term')).toLowerCase() === 'detail' && (
                <ContentDetail
                    title="Billing Detail"
                    prevTitle="Billing"
                    prevUrl="/profile#billing"
                />
            )}
            <ModalSpinnerPleaseWait show={pleaseWaitShow} />
            <section className="content">
                <div className="container-fluid">
                    {isLoading && (
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="overlay">
                                    <i className="fas fa-2x fa-sync-alt fa-spin" />
                                </div>
                            </div>
                        </div>
                    )}
                    {!isLoading && getBilling && (
                        <div className="row">
                            <div className="col-12">
                                <div className="invoice p-3 mb-3">
                                    <div className="row hid">
                                        <div className="col-12">
                                            <h4>
                                                INVOICE
                                                <small className="float-right">
                                                    {DateTime.fromISO(
                                                        getBilling.billing
                                                            .billingDate
                                                    ).toFormat('dd/MM/yyyy')}
                                                </small>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row invoice-info">
                                        <div className="col-sm-4 invoice-col">
                                            <b>
                                                Invoice #
                                                {getBilling.billing.billingNo}
                                            </b>
                                            <br />
                                            <b>Order ID:</b>{' '}
                                            {getBilling.billing.orderNo}
                                            <br />
                                            <b>Payment Due:</b>{' '}
                                            {DateTime.fromISO(
                                                getBilling.billing.dueDate
                                            ).toFormat('dd LLL yyyy HH:mm')}
                                            <br />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 table-responsive">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Qty</th>
                                                        <th>Serial #</th>
                                                        <th>Description</th>
                                                        <th>Subtotal</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getBilling.billingDetail.map(
                                                        (item) => (
                                                            <tr
                                                                key={
                                                                    item.billingDetailId
                                                                }
                                                            >
                                                                <td>1</td>
                                                                <td>
                                                                    {
                                                                        item.serialNo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.description
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.currency
                                                                    }{' '}
                                                                    {Number(
                                                                        item.originalAmount
                                                                    ).toLocaleString()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <p className="text-muted well well-sm shadow-none mt-1">
                                                Payment notes.
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <div className="table-responsive mt-1">
                                                <table className="table">
                                                    <tr>
                                                        <th>Subtotal:</th>
                                                        <td>
                                                            {
                                                                getBilling
                                                                    .billing
                                                                    .currency
                                                            }{' '}
                                                            {Number(
                                                                getBilling
                                                                    .billing
                                                                    .baseAmount
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Tax (10%)</th>
                                                        <td>
                                                            {
                                                                getBilling
                                                                    .billing
                                                                    .currency
                                                            }{' '}
                                                            {Number(
                                                                getBilling
                                                                    .billing
                                                                    .taxAmount
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total:</th>
                                                        <td>
                                                            {
                                                                getBilling
                                                                    .billing
                                                                    .currency
                                                            }{' '}
                                                            {Number(
                                                                getBilling
                                                                    .billing
                                                                    .nettAmount
                                                            ).toLocaleString()}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {String(
                                        getBilling.billing.status
                                    ).toLowerCase() === 'pending' && (
                                        <div className="row">
                                            <div className="col-12">
                                                <button
                                                    type="button"
                                                    className="btn btn-success float-right no-print"
                                                    onClick={() => {
                                                        setPleaseWaitShow(true);
                                                        window.location.replace(
                                                            getBilling.payment
                                                                .invoiceUrl
                                                        );
                                                    }}
                                                >
                                                    <i className="far fa-credit-card" />{' '}
                                                    Submit Payment
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};

export default Invoice;

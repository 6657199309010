import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonIcon,
    ContentHeader,
    PaginationComponent
} from '@components';
import DocuGate from '@app/services/network/docugate';
import ArchivesTable from '@pages/document/ArchivesTable';

const Trash = () => {
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(false);

    const fetchDocumentAPI = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getProfileBilling(
                Number(pageNumber),
                recordsPerPage,
                'billingDate:desc'
            );

            if (response) {
                if (response.results) {
                    setRecordsData(response.results);
                    setRecordsTotal(response.totalResults);
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    /*
     * Initial
     */
    useEffect(() => {
        fetchDocumentAPI(1);
    }, []);

    /*
     * Watch current page
     */
    useEffect(() => {}, [recordsData]);
    useEffect(() => {}, [recordsTotal]);
    useEffect(() => {
        fetchDocumentAPI(currentPage);
    }, [currentPage]);

    return (
        <div>
            <ContentHeader title="Trash" />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="float-left">
                                <div className="input-group input-group-sm">
                                    <ButtonIcon
                                        icon="refresh"
                                        theme="outline-primary"
                                    />
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="input-group input-group-sm">
                                    <Button
                                        icon="deleteAll"
                                        theme="outline-danger"
                                    >
                                        Empty Trash
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-0">
                            <ArchivesTable
                                recordsData={recordsData}
                                isLoadingData={isLoading}
                            />
                        </div>
                        <div className="card-footer">
                            <PaginationComponent
                                recordsTotal={recordsTotal}
                                recordsPerPage={recordsPerPage}
                                isLoadingData={isLoading}
                                onPageChanges={setCurrentPage}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Trash;

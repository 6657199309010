import React from 'react';
import Button from 'react-bootstrap/Button';

const icons = {
    refresh: 'fas fa-sync-alt',
    create: 'fas fa-plus',
    edit: 'fas fa-edit',
    delete: 'fas fa-trash-alt',
    signing: 'fas fa-edit',
    send: 'fas fa-paper-plane'
};

const AppButtonIcon = ({
    isLoading,
    icon,
    theme = 'success',
    disabled,
    ...otherProps
}) => {
    let iconTemplate;

    if (icon && icons[icon]) {
        iconTemplate = <i className={`${icons[icon]}`} />;
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <Button
            {...otherProps}
            variant={theme}
            disabled={isLoading || disabled}
        >
            {iconTemplate}
        </Button>
    );
};

export default AppButtonIcon;

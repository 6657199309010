import PropTypes from 'prop-types';
import {v4 as uuidv4} from 'uuid';
import React from 'react';
import {DateTime} from 'luxon';

const BillingTabTable = ({
    recordsData,
    isLoadingData,
    goToPage,
    goToPaymentPage
}) => {
    return (
        <div>
            <div className="table-responsive">
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th>Billing Date</th>
                            <th>Billing Due Date</th>
                            <th>Description</th>
                            <th className="text-right">Amount</th>
                            <th>Status</th>
                            <th>
                                <br />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={6}>
                                    <b>Loading Data...</b>
                                </td>
                            </tr>
                        )}
                        {recordsData.length > 0 &&
                            !isLoadingData &&
                            recordsData.map((row, index) => {
                                return (
                                    <tr key={row.billingId}>
                                        <td>
                                            {DateTime.fromISO(
                                                row.billingDate
                                            ).toFormat('dd LLL yyyy HH:mm')}
                                        </td>
                                        <td>
                                            {DateTime.fromISO(
                                                row.dueDate
                                            ).toFormat('dd LLL yyyy HH:mm')}
                                        </td>
                                        <td>{row.descriptions || ''}</td>
                                        <td className="text-right">
                                            Rp.{' '}
                                            {Number(
                                                row.nettAmount
                                            ).toLocaleString() || 0}
                                        </td>
                                        <td>{row.status || ''}</td>
                                        <td>
                                            <div className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-block btn-outline-primary"
                                                    onClick={() =>
                                                        goToPage(
                                                            row.billingId,
                                                            row.status
                                                        )
                                                    }
                                                >
                                                    Detail
                                                </button>
                                                {String(
                                                    row.status
                                                ).toLowerCase() ===
                                                    'pending' && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-block btn-success"
                                                        onClick={() =>
                                                            goToPaymentPage(
                                                                row.billingId
                                                            )
                                                        }
                                                    >
                                                        Submit Payment
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        {recordsData.length <= 0 && !isLoadingData && (
                            <tr
                                key={`tr-rows-${uuidv4()}`}
                                className="text-center"
                            >
                                <td colSpan={7}>
                                    <b>No Data</b>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

BillingTabTable.propTypes = {
    recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    goToPage: PropTypes.func.isRequired
};

export default BillingTabTable;

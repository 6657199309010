import React, {useEffect, useState} from 'react';
import {Button, ContentDetail} from '@components';
import RouteUrl from '@app/routes/RouteUrl';
import {useHistory} from 'react-router-dom';
import DocuGate from '@app/services/network/docugate';
import Select from 'react-select';
import {toast} from 'react-toastify';

const DocumentsUploadForm = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState('Choose file');
    const [isSelected, setIsSelected] = useState(false);
    const [recordsData, setRecordsData] = useState([]);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const history = useHistory();

    const changeFileHandler = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setSelectedFileName(file.name);
        setIsSelected(true);
    };

    const returnPageDocument = () => {
        history.push(RouteUrl.Documents);
    };

    const handleCancel = async () => {
        history.push(RouteUrl.Documents);
    };

    const handlesubmit = async (value) => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('flowHeaderId', selectedOption.value);

            const response = await DocuGate.upload(formData);
            if (response) {
                toast.success('Document Uploaded Successfully');
                returnPageDocument();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPaginatedFlowAPI = async (pageNumber) => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getPaginatedFlowHeader(
                currentPage,
                recordsPerPage
            );
            if (response) {
                if (response.results) {
                    setRecordsData(
                        response.results.map((data) => {
                            return {
                                value: data.flowId,
                                label: data.flowName
                            };
                        })
                    );
                    setRecordsTotal(response.totalResults);
                    if (response.totalResults < 1) {
                        toast.success(
                            'Please create FLOW before uploading document!'
                        );
                        history.push(RouteUrl.TemplateFlowHeaderForm);
                    }
                }
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    /*
     * Initial
     */
    useEffect(() => {
        fetchPaginatedFlowAPI(1);
    }, []);

    return (
        <div>
            <ContentDetail
                title="Document Upload"
                prevTitle="Documents"
                prevUrl={RouteUrl.Documents}
                isShowTitle={false}
            />
            <section className="content">
                <div className="container">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Document</h3>
                        </div>
                        <div className="card-body p-0">
                            <form>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="fileToUpload">
                                            File Upload
                                        </label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="fileToUpload"
                                                    onChange={changeFileHandler}
                                                    accept="application/pdf"
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="fileToUpload"
                                                >
                                                    {selectedFileName}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="templateFlow">
                                            Select Template Flow
                                        </label>
                                        <Select
                                            id="templateFlow"
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={recordsData}
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <Button
                                                theme="secondary"
                                                className="mt-4"
                                                block
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-6">
                                            <Button
                                                disabled={
                                                    // eslint-disable-next-line no-bitwise
                                                    (selectedFile === null) |
                                                    (selectedOption === null)
                                                }
                                                className="mt-4"
                                                type="button"
                                                block
                                                onClick={handlesubmit}
                                            >
                                                Upload
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DocumentsUploadForm;

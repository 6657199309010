import React, {useEffect, useState} from 'react';
import {Button} from '@components';
import {useTranslation} from 'react-i18next';
import DocuGate from '@app/services/network/docugate';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import RouteUrl from '@app/routes/RouteUrl';

const AccountTab = ({isActive}) => {
    const [isDisable, setIsDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [t] = useTranslation();

    const submitUpdate = async (values) => {
        try {
            setIsLoading(true);
            const profile = {
                firstName: values.firstName,
                lastName: values.lastName,
                phoneNumber: values.phoneNumber,
                address: values.address
            };
            await DocuGate.updateProfile(profile);
            toast.success('Profile update is succeed!');
        } catch (error) {
            toast.error(error);
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
            setIsDisable(true);
        }
    };

    const handleCancel = async () => {
        await fetchProfile();
        setIsDisable(true);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            profileId: '',
            accountId: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            companyName: '',
            jobTitle: '',
            address: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().optional(),
            phoneNumber: Yup.number()
                .min(9, 'Phone between 9-15')
                .max(15, 'Phone between 9-15')
                .optional(),
            address: Yup.string().optional()
        }),
        onSubmit: async (values) => {
            await submitUpdate(values);
        },
        enableReinitialize: true
    });

    const fetchProfile = async () => {
        try {
            setIsLoading(true);
            const response = await DocuGate.getProfile();
            await formik.setValues({
                email: response.account.email || '',
                profileId: response.profile.profileId || '',
                accountId: response.profile.accountId || '',
                firstName: response.profile.firstName || '',
                lastName: response.profile.lastName || '',
                phoneNumber: response.profile.phoneNumber || '',
                companyName: response.profile.companyName || '',
                jobTitle: response.profile.jobTitle || '',
                address: response.profile.address || ''
            });
        } catch (error) {
            // dispatch(logoutUser());
        } finally {
            setIsLoading(false);
        }
    };

    const onKeyPressEvent = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!new RegExp('[0-9]').test(keyValue)) event.preventDefault();
    };

    useEffect(async () => {
        await fetchProfile();
    }, []);

    return (
        <div className={`tab-pane ${isActive ? 'active' : ''}`}>
            <form onSubmit={formik.handleSubmit} className="form-horizontal">
                <div className="form-group">
                    <label htmlFor="emailInput">
                        {t('profile.inputLabel.emailAddress')}
                    </label>
                    <input
                        disabled
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formik.values.email}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="firstNameInput">
                        {t('profile.inputLabel.firstName')}
                    </label>
                    <input
                        required
                        disabled={isDisable}
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        formik={formik}
                        value={formik.values.firstName}
                        {...formik.getFieldProps('firstName')}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastNameInput">
                        {t('profile.inputLabel.lastName')}
                    </label>
                    <input
                        disabled={isDisable}
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        formik={formik}
                        value={formik.values.lastName}
                        {...formik.getFieldProps('lastName')}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumberInput">
                        {' '}
                        {t('profile.inputLabel.phoneNumber')}
                    </label>
                    <input
                        disabled={isDisable}
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        formik={formik}
                        value={formik.values.phoneNumber}
                        {...formik.getFieldProps('phoneNumber')}
                        onKeyPress={onKeyPressEvent}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="addressInput">
                        {' '}
                        {t('profile.inputLabel.address')}
                    </label>
                    <input
                        disabled={isDisable}
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        formik={formik}
                        value={formik.values.address}
                        {...formik.getFieldProps('address')}
                    />
                </div>
                <div className="form-group row">
                    <div className="col-sm-10">
                        <Button
                            icon="edit"
                            className="mr-1"
                            theme="outline-primary"
                            hidden={!isDisable}
                            onClick={() => setIsDisable(false)}
                        >
                            Edit
                        </Button>
                        <Button
                            icon="save"
                            className="mr-1"
                            theme="outline-primary"
                            isLoading={isLoading}
                            hidden={isDisable}
                            type="submit"
                        >
                            Update
                        </Button>
                        <Button
                            icon="close"
                            className="ml-1 mr-1"
                            theme="outline-secondary"
                            hidden={isDisable}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AccountTab;
